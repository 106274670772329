import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./setting.css";
import Profileimg from "../../assests/images/icons/Big-profile.png";
import Camera from "../../assests/images/icons/camera.svg";
import CardIcon from "../../assests/images/icons/card-icon.png";
import Profile from "../../assests/images/icons/image.png";
import Shape from "../../assests/images/icons/shape.png";
import VisaIcon from "../../assests/images/icons/visa-icon.png";
import MainSidebar from "../../Components/MainSidebar";
import PopupInfoModal from "../../ModalForm/Popup/PopupInfoModel";
import weburls from "../../Weburls/weburls";
import { ReactComponent as Arrow } from "../../assests/images/icons/accordionArrow.svg";

import ChangePassword from "../../ModalForm/ChangePassword";
import DeleteAccount from "../../ModalForm/DeleteAccount";
import OtpModal from "../../ModalForm/Popup/OtpModal";
import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer";
import { Loading } from "../../Components/Loading/Loading";

const ToggleSwitch = ({ value, onChange }) => {
  return (
    <label className="switch">
      <input type="checkbox" checked={value} onChange={onChange} />
      <span className={`switch-slider round`}></span>
    </label>
  );
};

const Setting = () => {
  const navigate = useNavigate();
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const sidebarRef = useRef(null);
  const [data, setData] = useState();
  const [toggleTab, setToggleTab] = useState("details");
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [dashboardYesNo, setDashboardYesNo] = useState("Yes");
  const [budgetYesNo, setBudgetYesNo] = useState("Yes");
  const [cashflowYesNo, setCashflowYesNo] = useState("Yes");
  const [netWorthYesNo, setNetWorthYesNo] = useState("Yes");
  const [retirementYesNo, setRetirementYesNo] = useState("Yes");
  const [goalsYesNo, setGoalsYesNo] = useState("Yes");
  const [transactionsYesNo, setTransactionsYesNo] = useState("Yes");
  const [accountsYesNo, setAccountsYesNo] = useState("Yes");
  const [connectYesNo, setConnectYesNo] = useState("Yes");
  const [oneTimeOTP, setOneTimeOTP] = useState();

  const [currentPassword, setCurrentPassword] = useState();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const [revokeConfirmPassword, setRevokeConfirmPassword] = useState("");
  const [revokeConfirmEmail, setRevokeConfirmEmail] = useState("");

  const [darkMode, setDarkMode] = useState(false);
  const [lightMode, setLightMode] = useState(false);

  const [email2FAVerify, setEmail2FAVerify] = useState(false);
  const [passwordChanged, setPasswordChanged] = useState(false);
  const [accountDeleted, setAccoundDeleted] = useState(false);
  const [modalActionCompleted, setModalActionCompleted] = useState(false);
  const [userDetails, setUserDetails] = useState("");
  const [remainingTime, setRemainingTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const fileInputRef = useRef(null);
  const [planDetails, setPlanDetails] = useState();
  const [emailOtp, setEmailOtp] = useState("");

  const [modals, setModals] = useState({
    Otp: false,
    deleteAccount: false,
    changePassword: false,
  });
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userData.username;

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    setLightMode(false);
  };

  const toggleLightMode = () => {
    setLightMode(!lightMode);
    setDarkMode(false);
  };

  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);

  const settingApi = useCallback(async () => {
    setLoading(true);
    const response = await axios.get(weburls.Get_clients_by_uuid + `${uuid}`);
    if (response?.data) {
      setData(response?.data);
    } else {
      toast.error(response?.data?.errors?.detail || "No Role Found", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    setLoading(false);
  }, [uuid]);

  const oneTimeLogin = useCallback(async () => {
    setLoading(true);
    const response = await axios.get(weburls.OneTime_Login + `${uuid}`);
    if (response?.data) {
      setOneTimeOTP(response?.data.oneTimeToken);
    } else {
      toast.error(response?.data?.errors?.detail || "No Role Found", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    setLoading(false);
  }, [uuid]);

  const fetchedPlanData = useCallback(async () => {
    const body = { email: userEmail };
    try {
      const response = await axios.post(weburls.Get_Plan_Details, body, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setPlanDetails(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [userEmail]);

  useEffect(() => {
    fetchedPlanData();
    settingApi();
    getUserDetails();
    oneTimeLogin();
  }, [settingApi]);

  const getUserDetails = async () => {
    try {
      const response = await axios.get(weburls.Get_User_Details + `${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setUserDetails(response?.data);
    } catch (error) {
      toast.error("Something went wrong while fetching user details", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const changePassword = async () => {
    if (currentPassword === "" || currentPassword === undefined) {
      toast.error("Please Insert Current Password", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    if (currentPassword === newPassword) {
      toast.error(
        "Your current password and new password are same, please try again",
        {
          position: "top-right",
          autoClose: 2000,
        }
      );
      return;
    }
    const hasNumber = /\d/.test(newPassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword);

    if (!hasNumber || !hasSpecialChar) {
      toast.error(
        "New password must contain at least one number and one special character.",
        {
          position: "top-right",
          autoClose: 2000,
        }
      );
      return;
    }
    if (newPassword !== confirmPassword) {
      toast.error("New password and Confirm password do not match.", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    const data = {
      userUuid: uuid,
      oldPassword: currentPassword,
      newPassword: newPassword,
    };
    try {
      const response = await axios.post(weburls.Change_Password, data, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      if (
        response.data.code === 0 &&
        response.data.message === "Your password incorrect"
      ) {
        toast.error("Your current password incorrect", {
          position: "top-right",
          autoClose: 2000,
        });
        setToggleTab("security");
      } else if (response.data.message === "Unauthorized") {
        toast.success("Your password changed successfully", {
          position: "top-right",
          autoClose: 2000,
        });
        setCurrentPassword("");
        setNewPassword("");
        setConfirmPassword("");
        // setToggleTab('security');
        setModalActionCompleted(true);
      }
    } catch (error) {
      console.error("error", error);
    }
  };

  const updateUser = async () => {
    const userData = {
      firstName: data?.firstName,
      lastName: data?.lastName,
    };
    if (data?.firstName == "") {
      toast.error("First name cannot be empty", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }
    try {
      const response = await axios.put(
        `${weburls.Update_User}${uuid}`,
        userData,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response.data.code === 1) {
        toast.success("User details have been updated successfully", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch (error) {
      toast.error("There was a problem updating user details.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const deleteAccount = async () => {
    if (revokeConfirmEmail === "" || revokeConfirmEmail === undefined) {
      toast.error("Please Insert Email Address", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }

    if (revokeConfirmPassword === "" || revokeConfirmPassword === undefined) {
      toast.error("Please Insert Confirm Password", {
        position: "top-right",
        autoClose: 2000,
      });
      return;
    }

    const data = {
      email: revokeConfirmEmail,
      password: revokeConfirmPassword,
    };
    try {
      const response = await axios.put(
        `${weburls.Revoke_account}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response.data.code === 0) {
        toast.error("User email and password incorrect", {
          position: "top-right",
          autoClose: 2000,
        });
        // setToggleTab('security');
      } else if (response.data.code === 1) {
        toast.success("Your account de-activated successfully", {
          position: "top-right",
          autoClose: 2000,
        });
        setAccoundDeleted(true);
        setTimeout(() => {
          navigate("/");
        }, 3000);
      }
    } catch (error) {
      console.error("error", error);
      toast.error("Please enter valid email address", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const uploadImage = async (file) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("userProfile", file);
      const response = await axios.post(
        weburls.Upload_User_Image + `${uuid}`,
        formData,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      settingApi();
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error updating profile image:", error);
    } finally {
      setLoading(false);
    }
  };

  const submitOtp = async () => {
    if (emailOtp.length !== 6) {
      return toast.error("Please enter 6 digit code", {
        position: "top-right",
        autoClose: 2000,
      });
    }
    const data = {
      userUuid: uuid,
      otp: emailOtp,
    };
    try {
      const response = await axios.post(
        weburls.Enable_2Factor_Authentication,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response.data.code === 0) {
        toast.error("Incorrect code", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response.data.code === 1) {
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 2000,
        });
        getUserDetails();
        setModalActionCompleted(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggle2FAVerify = async () => {
    if (userDetails.emailVefried === "true") {
      revokeEmail2FactorAuthentication();
    } else {
      generateOtp();
    }
  };

  const generateOtp = async () => {
    setModals({ ...modals, Otp: true });
    try {
      await axios.post(
        weburls.Set_2Factor_Authenication,
        {
          email: data?.email,
        },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
    } catch (error) {
      toast.error("Something went wrong.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const revokeEmail2FactorAuthentication = async () => {
    try {
      await axios.post(
        weburls.Revoke_2Factor_Authentication,
        {
          userUuid: uuid,
        },
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success("Your 2FA verification has been disabled", {
        position: "top-right",
        autoClose: 2000,
      });
      getUserDetails();
    } catch (error) {
      toast.error("Something went wrong.", {
        position: "top-right",
        autoClose: 2000,
      });
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      uploadImage(file);
      setImage(file);
    }
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const Logoutpg = () => {
    localStorage.removeItem("budget-token");
    localStorage.removeItem("userData");
    navigate("/");
  };

  const handleOtpSubmit = (otp) => {
    setEmailOtp(otp);
  };

  const openModal = (modal) => setModals({ ...modals, [modal]: true });
  const closeModal = (modal) => {
    if (modal === "Otp") {
      setEmailOtp("");
    }
    setModals({ ...modals, [modal]: false });
    setModalActionCompleted(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const selectPlanType = (plan) => {
    switch (plan) {
      case "Monthly-Standard":
      case "Standard-Partner-Monthly":
      case "Standard-Partner-Yearly":
      case "Yearly-Standard":
        return "Standard";
      case "Monthly-Pro":
      case "Yearly-Pro":
      case "Pro-Partner-Monthly":
      case "Pro-Partner-Yearly":
        return "Pro";
      case "Monthly-Basic":
      case "Yearly-Basic":
        return "Basic";
      case "Monthly-Free":
        return "Free";
      case "Yearly-Free":
      case "1-month-free-trial":
      case "2-month-free-trial":
      case "3-month-free-trial":
        return "Free";
      case "Early-Bird":
        return "EarlyBird";
      case "Freemium":
        return "Freemium";
      default:
        return "Free";
    }
  };

  const selectPlanNameAmount = (plan, amount) => {
    switch (plan) {
      case "Monthly-Standard":
      case "Yearly-Standard":
        return `Standard - $${amount.toFixed(2)}`;
      case "Monthly-Free":
        return "14 day free trial";
      case "Monthly-Pro":
      case "Yearly-Pro":
        return `Pro - $${amount.toFixed(2)}`;
      case "Early-Bird":
        return `Early Bird - $${amount.toFixed(2)}`;
      case "Freemium":
        return "Free";
      case "1-month-free-trial":
        return "1 Month free trial";
      case "2-month-free-trial":
        return "2 Month free trial";
      case "3-month-free-trial":
        return "3 Month free trial";
      case "Standard-Partner-Monthly":
      case "Standard-Partner-Yearly":
        return "Standard/";
      case "Pro-Partner-Monthly":
      case "Pro-Partner-Yearly":
        return "Pro/";
      case "Monthly-Basic":
      case "Yearly-Basic":
        return `Basic - $${amount.toFixed(2)}`;
      default:
        return "14 day free trial";
    }
  };

  const planMonthYear = (plan) => {
    switch (plan) {
      case "Monthly-Standard":
      case "Monthly-Pro":
      case "Monthly-Basic":
        return "/Month";
      case "Monthly-Free":
      case "Freemium":
      case "1-month-free-trial":
      case "2-month-free-trial":
      case "3-month-free-trial":
        return "";
      case "Yearly-Pro":
      case "Yearly-Standard":
      case "Yearly-Basic":
        return "/Year";
      case "Early-Bird":
        return "/Year";
      case "Standard-Partner-Monthly":
      case "Pro-Partner-Monthly":
        return "/Monthly";
      case "Standard-Partner-Yearly":
      case "Pro-Partner-Yearly":
        return "/Yearly";
      default:
        return "";
    }
  };

  const selectPlanBackground = (plan) => {
    switch (plan) {
      case "Monthly-Standard":
      case "Standard-Partner-Monthly":
      case "Standard-Partner-Yearly":
      case "Yearly-Standard":
        return "plan-standard";
      case "Monthly-Pro":
      case "Yearly-Pro":
      case "Pro-Partner-Monthly":
      case "Pro-Partner-Yearly":
        return "plan-pro";
      case "Monthly-Basic":
      case "Yearly-Basic":
        return "plan-basic";
      case "Monthly-Free":
        return "plan-free-trial";
      case "Yearly-Free":
      case "1-month-free-trial":
      case "2-month-free-trial":
      case "3-month-free-trial":
        return "plan-free-trial";
      case "Early-Bird":
        return "plan-early-bird";
      case "Freemium":
        return "plan-free";
      default:
        return "plan-no";
    }
  };

  const selectPlanDescription = (plan) => {
    switch (plan) {
      case "Monthly-Standard":
      case "Yearly-Standard":
        return `Our most popular plan with the essentials. Includes access to most features such as dashboard, budget, cashflow, 4 goals, and 5 connections.`;
      case "Monthly-Free":
        return "Get access to all features of our Pro plan for 14 days for free including, dashboard, budget, cashflow, goals, net worth and unlimited connections.";
      case "Monthly-Pro":
      case "Yearly-Pro":
        return `Ideal for families and finance fanatics, full access to all features including, dashboard, budget, cashflow, goals, net worth and unlimited connections.`;
      case "Early-Bird":
        return `Thanks for being an early bird! Enjoy full access to all features in our pro plan including, dashboard, budget, cashflow, goals, net worth and unlimited connections.`;
      case "Freemium":
        return "Take control of your finances, for free, forever. Enjoy access to the dashboard, budget, cashflow, 2 goals, transactions and accounts.";
      case "1-month-free-trial":
        return "Get access to all features of our Pro plan for 1 month for free including, dashboard, budget, cashflow, goals, net worth and unlimited connections.";
      case "2-month-free-trial":
        return "Get access to all features of our Pro plan for 2 months for free including, dashboard, budget, cashflow, goals, net worth and unlimited connections.";
      case "3-month-free-trial":
        return "Get access to all features of our Pro plan for 3 months for free including, dashboard, budget, cashflow, goals, net worth and unlimited connections.";
      case "Standard-Partner-Monthly":
      case "Standard-Partner-Yearly":
        return "Our most popular plan with the essentials. Includes access to most features such as dashboard, budget, cashflow, 4 goals, and 5 connections.";
      case "Pro-Partner-Monthly":
      case "Pro-Partner-Yearly":
        return "Ideal for families and finance fanatics, full access to all features including, dashboard, budget, cashflow, goals, net worth and unlimited connections.";
      case "Monthly-Basic":
      case "Yearly-Basic":
        return `For the basic budgeter, limited features, including dashboard, budget, 2 goals, accounts, 6 months of transactions and 2 connections.`;
      default:
        return "Get access to all features of our Pro plan for 14 days for free including, dashboard, budget, cashflow, goals, net worth and unlimited connections.";
    }
  };

  const selectPlanBtnColor = (plan) => {
    switch (plan) {
      case "Monthly-Standard":
      case "Standard-Partner-Monthly":
      case "Standard-Partner-Yearly":
      case "Yearly-Standard":
        return "#145BFF";
      case "Monthly-Pro":
      case "Yearly-Pro":
      case "Pro-Partner-Monthly":
      case "Pro-Partner-Yearly":
        return "#56D77A";
      case "Monthly-Basic":
      case "Yearly-Basic":
        return "#8914FF";
      case "Monthly-Free":
        return "#469B88";
      case "Yearly-Free":
      case "1-month-free-trial":
      case "2-month-free-trial":
      case "3-month-free-trial":
        return "#469B88";
      case "Early-Bird":
        return "#CD56D7";
      case "Freemium":
        return "#43B9F6";
      default:
        return "#D75656";
    }
  };

  useEffect(() => {
    if (planDetails && planDetails.endDate) {
      const interval = setInterval(() => {
        const endDate = new Date(planDetails.endDate);
        const now = new Date();

        // Calculate the difference in milliseconds
        const difference = endDate - now;

        if (difference > 0) {
          const days = Math.floor(difference / (1000 * 60 * 60 * 24));
          const hours = Math.floor(
            (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
          );
          const minutes = Math.floor(
            (difference % (1000 * 60 * 60)) / (1000 * 60)
          );
          const seconds = Math.floor((difference % (1000 * 60)) / 1000);

          // Format the remaining time
          setRemainingTime(`${days}d ${hours}H ${minutes}m ${seconds}s`);
        } else {
          // Countdown finished
          setRemainingTime("Expired");
          clearInterval(interval);
        }
      }, 1000);

      // Clean up interval when component is unmounted
      return () => clearInterval(interval);
    }
  }, [planDetails]);

  return (
    <>
      <div className="app-main__outer your-setting-page-container">
        <div className="header-section">
          <div className="col-md-6">
            <div className="fs-3 bold big-head-new">Your settings</div>
            <div className="fst-normal setting_text_color">
              Lets get the nuts and bolts sorted to optimise your account
              experience.
            </div>
          </div>
          <div className="col-md-6 right-section your-setting-page-back-portal">
            <div className="setting_background_color right-section">
              <button
                className="save-button"
                type="button"
                onClick={updateUser}
              >
                Save changes
              </button>
              {/* <div className="">
                      <img
                        src={Shape}
                        alt="Mortage_img"
                        onClick={togglePopupInfo}
                        // style={{ width: "20px", marginLeft: "20px" }}
                      />
                    </div> */}

              {isPopupOpenInfo && (
                <PopupInfoModal
                  isOpen={isPopupOpenInfo}
                  onClose={togglePopupInfo}
                  title="Settings ⚙️"
                  description={[
                    "Manage all your details, preferences, and security settings here.",
                    "Update your details by clicking the name, phone, or email text boxes. Turn certain notifications off and on to suit your needs.",
                    "Set up extra layers of security like Face 2FA, and change any details at any time. Change the appearance of BudgetBuddie by turning it to dark mode. ",
                    "View when your next payment is being processed and what card you’re using.",
                  ]}
                />
              )}
              <div className="profile-dashboard">
                <div className="profile-img">
                  <img
                    src={data?.avatar ? data?.avatar : Profile}
                    alt="profile"
                    style={{
                      height: "59px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row setting-page">
          <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane col-xl-12 fade show active d-flex"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div className="col-xl-9 setting-left-section mt-4 your-details-section">
                  <div className="detail-title-flex">
                    <div className="heading bold black">
                      <div className="your-details-title">Your details</div>
                    </div>
                  </div>
                  <div className="main-section-left">
                    <form className="row1">
                      <div className="field-form col-xl-5">
                        <label className="label-text">First name</label>
                        <input
                          type="text"
                          name="firstName"
                          value={data?.firstName}
                          className="edit-settings-input py-2 inter add-goal full-width-transaction"
                          style={{ border: "none", width: "231px" }}
                          onChange={handleChange}
                        />
                      </div>

                      <div className="field-form col-xl-4">
                        <label className="label-text">Last name</label>
                        <input
                          type="text"
                          name="lastName"
                          value={data?.lastName}
                          className="edit-settings-input py-2 inter add-goal full-width-transaction"
                          style={{ border: "none", width: "231px" }}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="field-form col-xl-8">
                        <label className="label-text">Your email</label>
                        <div
                          className="edit-settings-input py-2 inter add-goal"
                          style={{ width: "347px" }}
                        >
                          {data?.email}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-xl-6 profile-wrp">
                  <div className="profile-img-setting profile-img-margin">
                    <img
                      src={
                        data?.avatar === "" || data?.avatar === null
                          ? Profileimg
                          : data?.avatar
                      }
                      alt="profile imag"
                      style={{
                        width: "133px",
                        height: "133px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                    <div>
                      <div className="camera" onClick={handleIconClick}>
                        <img src={Camera} alt="camera" />
                      </div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                  {/* <div className="profile-img-setting btn-padding">
                          <button className="setting-logout-btn" onClick={Logoutpg}>
                            Log out
                          </button>
                        </div> */}
                  {/* <p className="version-padding">Version 1.1</p> */}
                </div>
              </div>
            <div className="mt-4 section-main">
              <div className="left-flex">
                <div className="heading bold black">
                  <div className="your-details-title">Security</div>
                </div>
                <div className="security-main mt-2">
                  <div className="sec-left">
                    <div className="main-sec-section-left">
                      <div className="heading bold black">
                        <h3
                          className="header-delete-acc-h3"
                          style={{ fontWeight: "500" }}
                        >
                          2-Factor Authentication
                        </h3>
                        <h3
                          className="header-delete-acc-h3"
                          style={{ float: "right" }}
                        >
                          <p className="header-p">
                            <div className="account-number inter text-16px">
                              <div className="button-blck">
                                <ToggleSwitch
                                  value={
                                    userDetails.emailVefried === "false"
                                      ? false
                                      : true
                                  }
                                  onChange={toggle2FAVerify}
                                />
                              </div>
                            </div>
                          </p>
                        </h3>
                      </div>
                    </div>
                    <div
                      className="change-pass"
                      onClick={() => openModal("changePassword")}
                    >
                      <div className="heading bold black">
                        <h3 className="header-delete-acc-h3">
                          Change password
                        </h3>
                        <h3
                          className="header-delete-acc-h3"
                          style={{ float: "right" }}
                        >
                          <p className="header-p">
                            <div className="account-number inter text-16px">
                              <div className="button-blck">
                                <Arrow />
                              </div>
                            </div>
                          </p>
                        </h3>
                      </div>
                    </div>
                    <div
                      className="change-pass"
                      onClick={() => openModal("deleteAccount")}
                    >
                      <div className="heading bold black">
                        <h3 className="header-delete-acc-h3">Delete account</h3>
                        <h3
                          className="header-delete-acc-h3"
                          style={{ float: "right" }}
                        >
                          <p className="header-p">
                            <div className="account-number inter text-16px">
                              <div className="button-blck">
                                <Arrow />
                              </div>
                            </div>
                          </p>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="payment-container">
                <div className="heading bold black">
                  <div className="your-details-title">Payments & billing</div>
                </div>
                <div className="sec-right mt-2">
                  {planDetails &&
                    selectPlanType(planDetails.planType) === "Free" &&
                    remainingTime !== "" && (
                      <div className="plan-green">
                        <span className="payment-desc">
                          Free trial remaining
                        </span>
                        <div className="rem-background">
                          <span className="rem-time">{remainingTime}</span>
                        </div>
                      </div>
                    )}
                  <div
                    className={`${
                      planDetails && selectPlanBackground(planDetails.planType)
                    }`}
                  >
                    {/* <div className={`plan-standard`}> */}
                    <span className="payment-desc" style={{ fontSize: "20px" }}>
                      {planDetails &&
                        selectPlanNameAmount(
                          planDetails.planType,
                          planDetails.amount
                        )}
                      <span className="payment-monthYear">
                        {planDetails && planMonthYear(planDetails.planType)}
                      </span>
                    </span>
                    <p className="pur-plan-para">
                      {planDetails &&
                        selectPlanDescription(planDetails.planType)}
                    </p>
                    <div
                      className="update-plan"
                      style={{
                        color: `${
                          planDetails &&
                          selectPlanBtnColor(planDetails.planType)
                        }`,
                      }}
                      onClick={() =>
                        window.open(
                          `https://account.budgetbuddie.com/manageAccountLogin?userToken=${oneTimeOTP}&userUuid=${uuid}`,
                          "_blank"
                        )
                      }
                    >
                      {" "}
                      Update plan
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {modals.changePassword && (
              <ChangePassword
                modalOpen={modals.changePassword}
                setModalOpen={openModal}
                setNewPassword={setNewPassword}
                setConfirmPassword={setConfirmPassword}
                setCurrentPassword={setCurrentPassword}
                currentPassword={currentPassword}
                confirmPassword={confirmPassword}
                newPassword={newPassword}
                changePassword={changePassword}
                closeModal={closeModal}
                modalActionCompleted={modalActionCompleted}
              />
            )}
            {modals.deleteAccount && (
              <DeleteAccount
                modalOpen={modals.deleteAccount}
                setModalOpen={openModal}
                closeModal={closeModal}
                revokeConfirmEmail={revokeConfirmEmail}
                setRevokeConfirmEmail={setRevokeConfirmEmail}
                revokeConfirmPassword={revokeConfirmPassword}
                setRevokeConfirmPassword={setRevokeConfirmPassword}
                deleteAccount={deleteAccount}
                accountDeleted={accountDeleted}
                setAccoundDeleted={setAccoundDeleted}
                modalActionCompleted={modalActionCompleted}
              />
            )}
            {modals.Otp && (
              <OtpModal
                modalOpen={modals.Otp}
                setModalOpen={openModal}
                closeModal={closeModal}
                revokeConfirmEmail={revokeConfirmEmail}
                setRevokeConfirmEmail={setRevokeConfirmEmail}
                revokeConfirmPassword={revokeConfirmPassword}
                setRevokeConfirmPassword={setRevokeConfirmPassword}
                deleteAccount={deleteAccount}
                accountDeleted={accountDeleted}
                setAccoundDeleted={setAccoundDeleted}
                handleOtpSubmit={handleOtpSubmit}
                submitOtp={submitOtp}
                setModalActionCompleted={setModalActionCompleted}
                modalActionCompleted={modalActionCompleted}
              />
            )}
          </div>
        </div>
        {loading && (
          <OverlayContainer>
            <div>
              <Loading />
            </div>
          </OverlayContainer>
        )}
      </div>
    </>
  );
};
export default Setting;
