export const mapAkahuAccountsData = (data) => {
  return data?.map((item) => {
    return {
      akahuAccountName: item?.aku_bank_name,
      accounts: item?.accounts.map((act) => ({
        status: act?.account_status,
        accountName: act?.account_name,
        refreshDate: act?.aku_refreshed_balance,
        img: act?.avatar,
        akahuAccountId: act?.aku_account_id,
        akahuAccountName: item?.aku_bank_name,
      })),
    };
  });
};

export const parseYourAccountBalancesData = (data) => {
  return data?.map((item) => {
    return {
      accountName: item?.accountName,
      avatar: item?.avatar,
      value: item?.value,
      date: item?.updatedAt,
      accountNumber: item?.akahuAccountNumber,
      accountId: item?.accountUuid,
      id: item.id,
      isAkahu: item.isAkahu,
    };
  });
};

export const parseAssetOrLiabilityData = (data) => {
  return data?.map((item) => {
    return {
      name: item?.assetsName ?? item?.liabilityName,
      emoji: item?.emoji,
      value: item?.value,
      id: item?.assetsUuid ?? item?.liabilityUuid,
      amount: item?.value,
      accounts: item?.accounts,
      isConnected: item?.isConnected,
    };
  });
};

export const parseMerchantsData = (data) => {
  return data?.map((item) => {
    return {
      name: item?.name,
      amount: item?.total_transaction_amount,
      date: item?.updated_at,
      id: item?.assetsUuid ?? item?.liabilityUuid,
      logo: item?.logo,
      categoryName: item?.categorie_name,
      categoryColor: item?.categorie_colour,
      categoryEmoji: item?.categorie_emoji,
      merchantId: item?.uuid,
      transactioncount: item?.total_transactions,
    };
  });
};

export const parseRetirementPlannerAccounts = (data) => {
  return data?.map((item) => {
    return {
      name: item?.aku_bank_name,
      amount: item?.amount,
      date: item?.updated_at,
      id: item?.account_id,
      logo: item?.logo,
      type: item?.type,
    };
  });
};

export const parseGoalData = (data) => {
  return {
    accounts: data.accounts,
    colour: data.colour,
    contribution: data.contribution,
    currentBalance: data.currentBalance,
    goalComplete: data.goalComplete,
    goalName: data.goalName,
    goalUuid: data.goalUuid,
    howOften: data.howOften,
    icon: data.icon,
    isConnected: data.isConnected || false,
    startDate: data.startDate,
    endDate: data.endDate,
    userUuid: data.userUuid,
    value: data.value,
  };
};
const formatDateWithSuffix = (date) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const yesterday = new Date(today);
  yesterday.setDate(today.getDate() - 1);

  const formatDate = (d) => {
    const suffix = (d) => {
      const j = d % 10;
      const k = Math.floor(d / 10);
      if (k === 1) return "th";
      return j === 1 ? "st" : j === 2 ? "nd" : j === 3 ? "rd" : "th";
    };

    const day = d.getDate();
    const month = d.toLocaleString("en-GB", { month: "long" });
    return `${day}${suffix(day)} ${month}`;
  };

  if (date.toDateString() === today.toDateString()) {
    return "Today";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday";
  } else {
    return formatDate(date);
  }
};

const formatMonthYear = (date) => {
  const options = { year: "numeric", month: "long" };
  return new Intl.DateTimeFormat("en-GB", options).format(date);
};

export const groupTransactionsByDate = (transactions, oldData) => {
  const mergedData = { ...oldData };
  let expenseCount = 0;
  let incomeCount = 0;

  transactions.forEach((transaction) => {
    const date = new Date(transaction.dateTime);
    const formattedDate = formatDateWithSuffix(date);
    if (!mergedData[formattedDate]) {
      mergedData[formattedDate] = [];
    }

    mergedData[formattedDate].push(transaction);
    if (transaction.amount < 0) {
      expenseCount += 1;
    } else {
      incomeCount += 1;
    }
  });
  return {
    data: mergedData,
    expenseCount,
    incomeCount,
  };
};

export const groupTransactionsByMonth = (transactions, oldData) => {
  const mergedData = { ...oldData };
  let expenseCount = 0;
  let incomeCount = 0;
  transactions.forEach((transaction) => {
    const date = new Date(transaction.dateTime);
    const formattedMonthYear = formatMonthYear(date);
    if (!mergedData[formattedMonthYear]) {
      mergedData[formattedMonthYear] = [];
    }
    mergedData[formattedMonthYear].push(transaction);
    if (transaction.amount < 0) {
      expenseCount += 1;
    } else {
      incomeCount += 1;
    }
  });
  return {
    data: mergedData,
    expenseCount,
    incomeCount,
  };
};

export const countTransactions = (transactions) => {
  const expenseCount = transactions.filter(
    (transaction) => transaction.amount < 0
  ).length;
  const incomeCount = transactions.filter(
    (transaction) => transaction.amount >= 0
  ).length;

  return { expenseCount, incomeCount };
};

export function formatNumberWithCommas(number) {
  const formattedNumber = parseFloat(number).toFixed(2);
  return formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const getOrdinalSuffix = (index) => {
  const j = (index + 1) % 10;
  const k = Math.floor(((index + 1) % 100) / 10);
  if (k === 1) return `${index + 1}th`;
  return `${index + 1}${
    j === 1 ? "st" : j === 2 ? "nd" : j === 3 ? "rd" : "th"
  }`;
};

export const getCurrentWeek = () => {
  const today = new Date();

  const firstDayOfWeek = new Date(
    today.setDate(today.getDate() - today.getDay() + 1)
  );

  const lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

  const getDayWithSuffix = (day) => {
    const suffixes = ["th", "st", "nd", "rd"];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };

  const startDay = getDayWithSuffix(firstDayOfWeek.getDate());
  const endDay = getDayWithSuffix(lastDayOfWeek.getDate());

  const startMonth = firstDayOfWeek.toLocaleString("default", {
    month: "short",
  });
  const endMonth = lastDayOfWeek.toLocaleString("default", { month: "short" });

  if (startMonth === endMonth) {
    return `${startDay} - ${endDay} ${startMonth}`;
  } else {
    return `${startDay} ${startMonth} - ${endDay} ${endMonth}`;
  }
};

export const getLastWeek = () => {
  const today = new Date();
  const firstDayOfLastWeek = new Date(today);
  firstDayOfLastWeek.setDate(today.getDate() - today.getDay() - 7);
  const lastDayOfLastWeek = new Date(firstDayOfLastWeek);
  lastDayOfLastWeek.setDate(firstDayOfLastWeek.getDate() + 6);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day}${getOrdinal(day)} ${month}`;
  };

  const getOrdinal = (n) => {
    if (n > 3 && n < 21) return "th";
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${formatDate(firstDayOfLastWeek)} - ${formatDate(lastDayOfLastWeek)}`;
};

export const getCurrentMonth = () => {
  const today = new Date();
  const options = { month: "short", year: "numeric" };
  return today.toLocaleDateString(undefined, options);
};

export const getLastMonth = () => {
  const today = new Date();
  const lastMonthDate = new Date(today.setMonth(today.getMonth() - 1));
  const options = { month: "short", year: "numeric" };
  return lastMonthDate.toLocaleDateString(undefined, options);
};

export const getCurrentQuarter = () => {
  const today = new Date();
  const quarterStartMonths = [0, 3, 6, 9];
  const quarter = Math.floor(today.getMonth() / 3);
  const startMonth = quarterStartMonths[quarter];

  const startOfQuarter = new Date(today.getFullYear(), startMonth, 1);
  const endOfQuarter = new Date(today.getFullYear(), startMonth + 3, 0);

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day}${getOrdinal(day)} ${month}`;
  };

  const getOrdinal = (n) => {
    if (n > 3 && n < 21) return "th";
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${formatDate(startOfQuarter)} - ${formatDate(endOfQuarter)}`;
};

export const getLastQuarter = () => {
  const today = new Date();
  const quarterStartMonths = [0, 3, 6, 9];
  const currentQuarter = Math.floor(today.getMonth() / 3);
  const lastQuarterIndex = currentQuarter === 0 ? 3 : currentQuarter - 1;
  const year =
    currentQuarter === 0 ? today.getFullYear() - 1 : today.getFullYear();

  const startOfLastQuarter = new Date(
    year,
    quarterStartMonths[lastQuarterIndex],
    1
  );
  const endOfLastQuarter = new Date(
    year,
    quarterStartMonths[lastQuarterIndex] + 3,
    0
  );

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day}${getOrdinal(day)} ${month}`;
  };

  const getOrdinal = (n) => {
    if (n > 3 && n < 21) return "th";
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${formatDate(startOfLastQuarter)} - ${formatDate(endOfLastQuarter)}`;
};

export const getCurrentYear = () => {
  const today = new Date();
  return today.getFullYear().toString();
};

export const getCurrentFortnight = () => {
  const today = new Date();
  let startOfFortnight, endOfFortnight;

  if (today.getDate() <= 15) {
    startOfFortnight = new Date(today.getFullYear(), today.getMonth(), 1);
    endOfFortnight = new Date(today.getFullYear(), today.getMonth(), 15);
  } else {
    startOfFortnight = new Date(today.getFullYear(), today.getMonth(), 16);
    endOfFortnight = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  }

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day}${getOrdinal(day)} ${month}`;
  };

  const getOrdinal = (n) => {
    if (n > 3 && n < 21) return "th";
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${formatDate(startOfFortnight)} - ${formatDate(endOfFortnight)}`;
};

export const getLastFortnight = () => {
  const today = new Date();
  let startOfFortnight, endOfFortnight;

  if (today.getDate() <= 15) {
    const lastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    startOfFortnight = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth(),
      16
    );
    endOfFortnight = new Date(
      lastMonth.getFullYear(),
      lastMonth.getMonth() + 1,
      0
    );
  } else {
    startOfFortnight = new Date(today.getFullYear(), today.getMonth(), 1);
    endOfFortnight = new Date(today.getFullYear(), today.getMonth(), 15);
  }

  const formatDate = (date) => {
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day}${getOrdinal(day)} ${month}`;
  };

  const getOrdinal = (n) => {
    if (n > 3 && n < 21) return "th";
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return `${formatDate(startOfFortnight)} - ${formatDate(endOfFortnight)}`;
};

function hexToHSL(hex) {
  let r = parseInt(hex.slice(1, 3), 16) / 255;
  let g = parseInt(hex.slice(3, 5), 16) / 255;
  let b = parseInt(hex.slice(5, 7), 16) / 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;
  if (max === min) {
    h = s = 0; // achromatic
  } else {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
      default:
        break;
    }
    h /= 6;
  }
  return { h: h * 360, s: s * 100, l: l * 100 };
}
function HSLToHex(h, s, l) {
  s /= 100;
  l /= 100;
  let c = (1 - Math.abs(2 * l - 1)) * s;
  let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
  let m = l - c / 2;
  let r = 0,
    g = 0,
    b = 0;
  if (0 <= h && h < 60) {
    r = c;
    g = x;
    b = 0;
  } else if (60 <= h && h < 120) {
    r = x;
    g = c;
    b = 0;
  } else if (120 <= h && h < 180) {
    r = 0;
    g = c;
    b = x;
  } else if (180 <= h && h < 240) {
    r = 0;
    g = x;
    b = c;
  } else if (240 <= h && h < 300) {
    r = x;
    g = 0;
    b = c;
  } else if (300 <= h && h < 360) {
    r = c;
    g = 0;
    b = x;
  }
  r = Math.round((r + m) * 255);
  g = Math.round((g + m) * 255);
  b = Math.round((b + m) * 255);
  return `#${((1 << 24) + (r << 16) + (g << 8) + b)
    .toString(16)
    .slice(1)
    .toUpperCase()}`;
}

export function colorToExtraLightenColor(hex, amount = 0.315) {
  const hsl = hexToHSL(hex);
  hsl.l = Math.min(100, hsl.l + amount * 100);
  return HSLToHex(hsl.h, hsl.s, hsl.l);
}
