/* eslint-disable react/jsx-pascal-case */
import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./BudgetSummary.css";
import { ReactComponent as Arrow } from "../../../assests/images/Arrow_right.svg";
import { ReactComponent as Arrow_right } from "../../../assests/images/Arrow_right_.svg";
import BudgetBreakdown from "./BudgetBreakdown";
import BudgetsComponent from "./BudgetsComponent";
import { toast } from "react-toastify";
import weburls from "../../../Weburls/weburls";
import axios from "axios";
import OverlayContainer from "../../OverlayContainer/OverlayContainer";
import { Loading } from "../../Loading/Loading";
import {
  getCurrentMonth,
  getCurrentQuarter,
  getCurrentWeek,
  getCurrentYear,
  getLastMonth,
  getLastQuarter,
  getLastWeek,
} from "../../../utilities/helper";
import OneOffBudget from "./OneOffBudget";
import UserImageInfoToggle from "../../UserImageInfoToggle";
import PageTitle from "../../PageTitle";

const BudgetSummary = () => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const [isBudgetBreakToggled, setIsBudgetBreakToggled] = useState(false);
  const [incomeBudgetsData, setIncomeBudgetsData] = useState([]);
  const [expenseBudgetsData, setExpenseBudgetsData] = useState([]);
  const [oneOffBudgetsData, setOneOffBudgetsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [completeBudgetDataIncome, setCompleteBudgetDataIncome] = useState();
  const [completeBudgetDataExpense, setCompleteBudgetDataExpense] = useState();
  const [remainingAmount, setRemainingAmount] = useState({
    income: 0,
    expense: 0,
  });

  const [totalAmount, setTotalAmount] = useState({
    income: 0,
    expense: 0,
  });

  const [selectedPeriod, setSelectedPeriod] = useState("lastWeek");

  const handleToggleClick = () => {
    setIsBudgetBreakToggled(!isBudgetBreakToggled);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day}${getOrdinal(day)} ${month}`;
  };

  const getOrdinal = (n) => {
    if (n > 3 && n < 21) return "th";
    switch (n % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  const formatPeriod = (start, end) =>
    `${formatDate(start)} - ${formatDate(end)}`;

  const formatCustomLastPeriod = function (startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const duration = end - start;

    const millisecondsPerDay = 1000 * 60 * 60 * 24;
    const days = Math.floor(duration / millisecondsPerDay);

    let newEndDate = new Date(start);
    newEndDate.setDate(newEndDate.getDate() - 1);

    let newStartDate = new Date(newEndDate);
    newStartDate.setDate(newStartDate.getDate() - days);

    return `${formatDate(newStartDate)} - ${formatDate(newEndDate)}`;
  };

  const options = [
    { label: "lastWeek", text: "Last week", date: getLastWeek() },
    { label: "thisWeek", text: "This week", date: getCurrentWeek() },
    {
      label: "lastFortnightly",
      text: "Last fortnight",
      date: completeBudgetDataExpense?.expenses?.lastFortnightly?.length
        ? formatPeriod(
            completeBudgetDataExpense.expenses.lastFortnightly[0]
              .fortnight_start_date,
            completeBudgetDataExpense.expenses.lastFortnightly[0]
              .fortnight_end_date
          )
        : "No data available",
    },
    {
      label: "thisFortnightly",
      text: "This fortnight",
      date: completeBudgetDataExpense?.expenses?.thisFortnightly?.length
        ? formatPeriod(
            completeBudgetDataExpense.expenses.thisFortnightly[0]
              .fortnight_start_date,
            completeBudgetDataExpense.expenses.thisFortnightly[0]
              .fortnight_end_date
          )
        : "No data available",
    },
    { label: "lastMonth", text: "Last month", date: getLastMonth() },
    { label: "monthly", text: "This month", date: getCurrentMonth() },
    { label: "lastQuarter", text: "Last quarter", date: getLastQuarter() },
    { label: "quarter", text: "This quarter", date: getCurrentQuarter() },
    { label: "thisYear", text: "This year", date: getCurrentYear() },

    {
      label: "lastCustom",
      text: "Custom - last period",
      date: completeBudgetDataExpense?.expenses?.thisCustom?.length
        ? formatCustomLastPeriod(
            completeBudgetDataExpense.expenses.thisCustom[0].start_date,
            completeBudgetDataExpense.expenses.thisCustom[0].end_date
          )
        : "No data available",
    },
    {
      label: "thisCustom",
      text: "Custom - this period",
      date: completeBudgetDataExpense?.expenses?.thisCustom?.length
        ? formatPeriod(
            completeBudgetDataExpense.expenses.thisCustom[0].start_date,
            completeBudgetDataExpense.expenses.thisCustom[0].end_date
          )
        : "No data available",
    },
  ];

  const handleLeftClick = () => {
    const newIndex = currentIndex === 0 ? options.length - 1 : currentIndex - 1;
    const newLabel = options[newIndex].label;
    setSelectedPeriod(newLabel);
    setCurrentIndex(newIndex);
    setIncomeBudgetsData(completeBudgetDataIncome?.income[newLabel]);
    setExpenseBudgetsData(completeBudgetDataExpense?.expenses[newLabel]);
  };

  const handleRightClick = () => {
    const newIndex = currentIndex === options.length - 1 ? 0 : currentIndex + 1;
    const newLabel = options[newIndex].label;
    setSelectedPeriod(newLabel);
    setCurrentIndex(newIndex);
    setIncomeBudgetsData(completeBudgetDataIncome?.income[newLabel]);
    setExpenseBudgetsData(completeBudgetDataExpense?.expenses[newLabel]);
  };

  const fetchIncomeBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_Income_Budget}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        setCompleteBudgetDataIncome(response?.data);
        setIncomeBudgetsData(response?.data.income.thisWeek);
      }
    } catch (error) {
      console.error("Error fetching income budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  const fetchExpenseBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_Expense_Budget}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        setCompleteBudgetDataExpense(response?.data);
        setExpenseBudgetsData(response?.data.expenses.thisWeek);
      }
    } catch (error) {
      console.error("Error fetching Expense budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  const fetchOneOffBudgetData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${weburls.Get_OneOff_Budget}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      if (response?.data?.code === "0") {
        toast.error(response?.data?.message || "An error occurred.", {
          position: "top-right",
          autoClose: 2000,
        });
      } else if (response?.data) {
        setOneOffBudgetsData(response.data);
      }
    } catch (error) {
      console.error("Error fetching One Off budget data:", error);
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
    } finally {
      setLoading(false);
    }
  }, [uuid]);

  useEffect(() => {
    if (uuid) {
      fetchIncomeBudgetData();
      fetchExpenseBudgetData();
      fetchOneOffBudgetData();
    }
  }, [
    uuid,
    fetchIncomeBudgetData,
    fetchExpenseBudgetData,
    fetchOneOffBudgetData,
  ]);

  return (
    <>
      <div className="app-main__outer budget-summary-main">
        <div className="row">
          <div className="header-budget-main-div">
            <div className="header-budget-text-div">
              <PageTitle
                title={"Budget summary"}
                description={
                  "Here`s your summary of your budget and how you`re tracking."
                }
              />
            </div>

            <div
              className="header-budget-sub-div"
              style={{ gap: "30px", alignItems: "center" }}
            >
              <div
                className="edit-btn"
                style={{
                  width: "327px",
                  height: "53px",
                  borderRadius: "360px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  backgroundColor: "#001f3f",
                }}
              >
                <div
                  className="left"
                  onClick={handleLeftClick}
                  style={{ cursor: "pointer", zIndex: 12, marginLeft: "-17px" }}
                >
                  <p style={{}}>
                    <Arrow_right />
                  </p>
                </div>

                <div className="" style={{ textAlign: "center" }}>
                  <h4>{options[currentIndex].text}</h4>
                  <p style={{ color: "#04baff" }}>
                    <p>{options[currentIndex].date}</p>
                  </p>
                </div>

                <div
                  className="right"
                  onClick={handleRightClick}
                  style={{
                    cursor: "pointer",
                    zIndex: 12,
                    marginRight: "-17px",
                  }}
                >
                  <Arrow />
                </div>
              </div>
              <UserImageInfoToggle customStyle={true} />
            </div>
          </div>
        </div>

        <div className="row " style={{ marginTop: "60px" }}>
          <div className="col">
            {/* Income Budgets Section */}

            <BudgetsComponent
              mainHeading="Income"
              incomeExpenseBudgetsData={incomeBudgetsData}
              setTotalAmount={setTotalAmount}
              setRemainingAmount={setRemainingAmount}
              isIncome={true}
              selectedPeriod={selectedPeriod}
              showFields={true}
              uuid={uuid}
              fetchBudgetData={fetchIncomeBudgetData}
            />
            <div className="mt-4">
              <BudgetsComponent
                mainHeading="Expense "
                setRemainingAmount={setRemainingAmount}
                setTotalAmount={setTotalAmount}
                incomeExpenseBudgetsData={expenseBudgetsData}
                isIncome={false}
                showFields={true}
                selectedPeriod={selectedPeriod}
                uuid={uuid}
                fetchBudgetData={fetchExpenseBudgetData}
              />
            </div>
          </div>

          {/* Budget Breakdown Card */}
          <BudgetBreakdown
            handleToggleClick={handleToggleClick}
            isBudgetBreakToggled={isBudgetBreakToggled}
            totalAmount={totalAmount}
            remainingAmount={remainingAmount}
          />
        </div>
        {/* End of Income Budgets Section */}

        <div className="row mt-4">
          <OneOffBudget
            mainHeading="One off "
            showFields={false}
            oneOffBudgetsData={oneOffBudgetsData.oneOffBudget}
            uuid={uuid}
            fetchBudgetData={fetchOneOffBudgetData}
          />
        </div>
      </div>
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default BudgetSummary;
