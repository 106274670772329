export const AutoCategorise = ({ handleYes, handleNo }) => {
  return (
    <div className="popup-container modal-background open-modal">
      <div className="popup-content">
        <h2> Auto-categorise?</h2>
        <p>
          Would you like to automatically categorise merchants & transactions
          with this category? This will override any that are already
          categorised.
        </p>
        <div className="popup-buttons">
          <button className="btn btn-no" onClick={handleNo}>
            No
          </button>
          <button className="btn btn-yes" onClick={handleYes}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpForCustomBudget = ({ handleConfirm, handleCancel }) => {
  return (
    <div className="popup-container modal-background open-modal">
      <div className="popup-content">
        <h2>Are you sure?</h2>
        <p>
          This will update the date range for all other income & expenses within
          this custom budget.
        </p>
        <div className="popup-buttons">
          <button className="btn btn-no" onClick={handleCancel}>
            Go back
          </button>
          <button className="btn btn-yes" onClick={handleConfirm}>
            Confirm change
          </button>
        </div>
      </div>
    </div>
  );
};
