import React from "react";
import { Popover, PopoverBody } from "reactstrap";
import { categoryBackgroundCss } from "../../utilities/Statics";

const PopOver = ({
  categories,
  position,
  transactionId,
  updateCategory,
  togglePopover,
  popoverOpen,
  handleChange,
  transactionType,
  updateCategoriesInBulk,
  isSelectionMode,
  updatingForSplitTransaction,
  onCategoryChangeForSplitting,
}) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  const handleCategoryClick = (category) => {
    if (updatingForSplitTransaction) {
      onCategoryChangeForSplitting({ ...category, id: transactionId });
    } else {
      updateCategory({ ...category, transactionId });
    }
    togglePopover(transactionId);
  };

  return (
    <Popover
      className="custom-popover"
      placement="bottom"
      isOpen={popoverOpen}
      target={`Popover-${transactionId}`}
      toggle={togglePopover}
    >
      <PopoverBody>
        <div
          className="transaction-categories  mt-3"
          style={{ top: position?.top, left: position?.left }}
        >
          <div className="bold popover-live">{transactionType} categories</div>
          <button
            onClick={() => togglePopover(transactionId)}
            className="close-pass-button"
          >
            <i class="fa fa-times"></i>
          </button>
          <div className="categories group-btn green-btn">
            <div className="categories group-btn green-btn ">
              <div className="button-blck"></div>
              {categories &&
                categories?.length > 0 &&
                categories.map((it) => (
                  <div
                    key={it?.userCategoryId}
                    className="button-blck"
                    onClick={() => handleCategoryClick(it)}
                    style={{ marginRight: "10px" }}
                  >
                    <div className={`icon-tag`}>
                      <div
                        className={`icon-transaction-img ${
                          isHexColor(it?.colour)
                            ? ""
                            : categoryBackgroundCss(it?.colour)
                        }`}
                        style={
                          isHexColor(it?.colour)
                            ? { backgroundColor: it?.colour }
                            : {}
                        }
                      >
                        {it?.emoji}
                      </div>
                      <div className="content">
                        <h6 className={`icon-content hello`}>{it?.name}</h6>
                      </div>
                    </div>
                  </div>
                ))}
              <button
                className={`new-category-btn green ${true <= 0 ? "green" : ""}`}
                onClick={handleChange}
              >
                + New category
              </button>
            </div>
          </div>
        </div>
      </PopoverBody>
    </Popover>
  );
};

export default PopOver;
