import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../assests/images/icons/BudgetBuddie word logo.svg";
import { ReactComponent as Client } from "../../assests/images/icons/client.svg";
import { ReactComponent as Doller } from "../../assests/images/icons/dollor.svg";
import { ReactComponent as Group8784 } from "../../assests/images/icons/Group 8784.svg";
import { ReactComponent as Group8785 } from "../../assests/images/icons/Group 8785.svg";
import { ReactComponent as Group8786 } from "../../assests/images/icons/Group 8786.svg";
import { ReactComponent as Group8787 } from "../../assests/images/icons/Group 8787.svg";
import { ReactComponent as Home } from "../../assests/images/icons/home.svg";
import { ReactComponent as Logout } from "../../assests/images/icons/nav-logout.svg";
import { ReactComponent as Setting } from "../../assests/images/icons/setting.svg";
import { ReactComponent as Shape } from "../../assests/images/icons/shape.svg";
import { ReactComponent as Trophy } from "../../assests/images/icons/trophy.svg";
import { ReactComponent as Wallet } from "../../assests/images/icons/wallet.svg";
import { menuItems } from "../../utilities/Statics";

const MainSidebar = ({ toggleSidebar, uuid }) => {
  const [isActive, setIsActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const items = menuItems(uuid);

  const Logoutpg = () => {
    localStorage.removeItem("budget-token");
    localStorage.removeItem("userData");
    navigate("/");
  };

  return (
    <>
      <div className="app-sidebar">
        <div className="scrollbar-sidebar">
          <div className="app-sidebar__inner">
            <div onClick={toggleSidebar}>
              <div
                className="only-logo"
                style={{ width: "900px", marginLeft: "18px" }}
              >
                <img src={Logo} alt="main-logo" className="logo-img" />
                {/* <h4 className="heading inline">BudgetBuddie</h4> */}
              </div>
              <a className="navbar-brand" href="#">
                <img src={Logo} alt="logo" />
                {/* <h4 className="heading inline">BudgetBuddie</h4> */}
              </a>
            </div>

            <ul className="vertical-nav-menu metismenu">
              {items.map((item, index) => (
                <div className="menu-flex" key={index}>
                  <div
                    className={`${
                      location.pathname.startsWith(item.path) ||
                      (item.path.includes("AccountBalances") &&
                        location.pathname.includes("AccountDetails")) ||
                      (item.path.includes("merchants") &&
                        location.pathname.includes("merchantdetails"))
                        ? "active-bar"
                        : ""
                    }`}
                  ></div>
                  <li key={index}>
                    <NavLink
                      to={item.path}
                      activeClassName="navbar__link--active"
                      className={`${
                        (item.path.includes("AccountBalances") &&
                          location.pathname.includes("AccountDetails")) ||
                        (item.path.includes("merchants") &&
                          location.pathname.includes("merchantdetails"))
                          ? "navbar__link active"
                          : "navbar__link"
                      }`}
                    >
                      <item.icon />
                      <span>{item.text}</span>
                    </NavLink>
                  </li>
                </div>
              ))}

              <div
                className="item-margin"
                style={{ marginTop: "0px", fontWeight: "500" }}
              >
                <li onClick={Logoutpg}>
                  <NavLink>
                    <Logout
                      style={{
                        width: "18px",
                        height: "18px",
                        marginRight: "4px",
                      }}
                    />
                    Logout
                  </NavLink>
                </li>
              </div>
            </ul>
          </div>
        </div>

        {/* Ensure the beta section stays pinned to the bottom */}
        <div className="beta-section">
          <div className="beta-banner">
            <span>We're currently in beta 🛠️</span>
          </div>
          <p className="beta-message">
            New features and improvements will be added every week.
          </p>
        </div>
      </div>

      <div className="main-nav">
        <div className={isActive ? "menu-link mobile-menu-link" : "menu-link"}>
          {isActive && (
            <span
              className="close-button"
              onClick={() => setIsActive(!isActive)}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </span>
          )}
          <ul>
            <li>
              <NavLink
                to="/"
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Home />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/budget/${uuid}`}
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Shape />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/merchants/${uuid}`}
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Client />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/networth/${uuid}`}
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Wallet />
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/Financial"
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Trophy />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/retirement/${uuid}`}
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Group8784 />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/cashflow/${uuid}`}
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Doller />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/Transactions/${uuid}`}
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Group8785 />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/AccountBalances/${uuid}`}
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Group8786 />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/connectaccounts/${uuid}`}
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Group8787 />
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`/SettingPage/${uuid}`}
                activeClassName="navbar__link--active"
                className="navbar__link"
              >
                <Setting />
              </NavLink>
            </li>
          </ul>
        </div>

        <div className="hamburger-menu">
          <a href="#" onClick={() => setIsActive(!isActive)}>
            <img src={Logo} alt="main-logo" />
          </a>
        </div>
      </div>
    </>
  );
};

export default MainSidebar;
