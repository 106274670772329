import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer } from 'react-toastify';
import '../../ModalForm/AddIncome/addIncome.css';
import { ReactComponent as Akahu } from "../../assests/images/icons/akahuIcon.svg";


// import './index.css';

const DeleteAccount = ({
  modalOpen,
  closeModal,

}) => {

  const redirectToAkahuPortal = () => {
    const url = "https://oauth.akahu.io/?client_id=app_token_clq0dnvnl000008jt0qdd62tw&response_type=code&redirect_uri=https%3A%2F%2Fbb.deesdata.com%2Fakahu&scope=ENDURING_CONSENT&email=tim@budgetbuddie.co.nz&state=htfkf";
    window.open(url, '_blank');
  };




  return (
    <div className={`modal-background ${modalOpen ? 'open-modal' : ''} `}>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <div className="modal-content delete-modal-width">
          <button onClick={closeModal} className="close-pass-button">
            <i class="fa fa-times"></i>
          </button>
        <div className="">
          <div className="d-flex justify-content-between">
            <div className="fs-3 connect-title-mb">
              <span className="bold black password-heading">Connect your accounts</span>
            </div>
          </div>
          <div className="akahu-box">
            <div className="d-flex align-items-center mb-3 akahu-modal-title">
              <div className="bank-connection-img">
                {/* <img src={Ellipse2} alt="" className="akahu-img" /> */}
                <Akahu/>
              </div>
              <div className="bold black who-akahu">Connect using Akahu</div>
            </div>
            <div className="setting_text_color mt-2">Akahu is New Zealand’s open finance provider.</div>
            <div className="setting_text_color mt-4">
              Akahu makes it simple to securely access your financial information and to provide that data with
              platforms like BudgetBuddie.
            </div>
            <div className="setting_text_color mt-3">
              Akahu uses 256-bit bank-grade encryption to keep your data safe and secure.
            </div>
            <div className="setting_text_color mt-3">
              With your consent, the secure connection with Akahu will pull through your bank balances, transactional
              information, and account details.
            </div>
            <div className="setting_text_color mt-3">
              You’ll be redirected to Akahu’s website to establish the connection.
            </div>
            <div className="setting_text_color mt-3">To connect your accounts, tap the below button.</div>
            {/* <div className="setting_text_color mt-3">To manage the connection use this link: my.akahu.io</div> */}
            <div className="flex-content-center"  onClick={redirectToAkahuPortal}>
              <div className="inves-btn">Connect my accounts</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccount;
