import axios from "axios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ReactComponent as Search } from "../../assests/images/icons/Search.svg";
import { ReactComponent as BulkIcon } from "../../assests/images/bulk-icon.svg";
import { ReactComponent as RulesIcon } from "../../assests/images/rules-icon.svg";
import { ReactComponent as SelectAllIcon } from "../../assests/images/icons/selectall.svg";
import MainSidebar from "../../Components/MainSidebar/index.jsx";
import weburls from "../../Weburls/weburls.jsx";
import {
  groupTransactionsByDate,
  groupTransactionsByMonth,
} from "../../utilities/helper.jsx";
import { TransactionListing } from "../Transactions/TransactionListing.jsx";

import OverlayContainer from "../../Components/OverlayContainer/OverlayContainer.jsx";
import { Loading } from "../../Components/Loading/Loading.jsx";
import TransactionViewModal from "../../ModalForm/TransactionModals/TransactionViewModal.jsx";
import useDebounce from "../../hooks/useDebouce.jsx";
import { dateOptions } from "../../utilities/Statics/index.jsx";
import Amount from "../../Components/Amount.jsx/index.jsx";
import SectionTitle from "../../Components/SectionTitle.jsx/index.jsx";
import PageTitle from "../../Components/PageTitle/index.jsx";
import UserImageInfoToggle from "../../Components/UserImageInfoToggle/index.jsx";
import Logo from "../../assests/images/icons/Black Logo 1.png";
import { isEmpty } from "lodash";
import NoDataFound from "../../Components/NoDataFound/index.jsx";
import FilterTags from "../../Components/FilterTags/index.jsx";
import AddTransactionRuleModel from "../../ModalForm/TransactionModals/AddTransactionRuleModel.jsx";
import { PopUpDelete } from "../../Components/BudgetDetails/PopUpForUpdateDelete.jsx";

const AccountDetails = () => {
  const { uuid } = useParams();
  const token = localStorage.getItem("budget-token");
  const navigate = useNavigate();
  const location = useLocation();
  const { avatar, accountName, value, accountNumber, accountId, isAkahu } =
    location.state || {};
  const sidebarRef = useRef(null);
  const [category, setCategory] = useState([]);
  const [icomecat, setIcomCat] = useState([]);
  const [filterByDate, setFilterByDate] = useState("");
  const [isPopupOpenInfo, setPopupOpenInfo] = useState(false);
  const [selectedExpenseCategories, setSelectedExpenseCategories] = useState(
    []
  );
  const [selectedIncomeCategories, setSelectedIncomeCategories] = useState([]);
  const togglePopupInfo = () => setPopupOpenInfo(!isPopupOpenInfo);
  const [currentMonthGrouped, setCurrentMonthGrouped] = useState({});
  const [remainingMonthsGrouped, setRemainingMonthsGrouped] = useState({});
  const [loading, setLoading] = useState(false);
  const [spliModalData, setSplitModalData] = useState();
  const [isSelectionMode, setSelectionMode] = useState(false);
  const [selectedForBulkUpdate, setSelectedForBulkUpdate] = useState({
    Expense: [],
    Income: [],
  });
  const [unPress, setUnpress] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [modals, setModals] = useState({
    viewTransaction: false,
  });

  const [search, setSearch] = useState("");
  const debouncedSearch = useDebounce(search, 500);

  const openModal = (modal, data) => {
    if (modal === "viewTransaction") {
      setSplitModalData(data);
    }
    setModals({ ...modals, [modal]: true });
  };
  const closeModal = (modal) => {
    setModals({ ...modals, [modal]: false });
  };

  const getCategory = useCallback(async () => {
    try {
      const response = await axios.get(`${weburls.Get_All_Categories}${uuid}`, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });
      setCategory(response?.data?.data?.transaction?.Expense);
      setIcomCat(response?.data?.data?.transaction?.INCOME);
    } catch (err) {
      toast.error(err);
    }
  }, [uuid]);

  const deleteCustomTransaction = async (id) => {
    try {
      const response = await axios.delete(
        `${weburls.Delete_Custom_Transactions}${uuid}/${id}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactions();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const handleDoneWithBulkUpdate = () => {
    setSelectionMode(false);
    setSelectAll(false);
    setSelectedForBulkUpdate({
      Expense: [],
      Income: [],
    });
  };

  const SelectAll = () => {
    if (selectAll) {
      return (
        <span
          className="select-all"
          onClick={() => handleSelectAllForBulkCategoryUpdate(false, true)}
        >
          Unselect all
        </span>
      );
    } else {
      return (
        <span
          className="select-all"
          onClick={() => handleSelectAllForBulkCategoryUpdate(true, false)}
        >
          Select all
        </span>
      );
    }
  };

  const handleSelectAllForBulkCategoryUpdate = (select, unSelect) => {
    const allTransactions = {
      Expense: [...selectedForBulkUpdate.Expense],
      Income: [...selectedForBulkUpdate.Income],
    };
    const data = {
      ...currentMonthGrouped,
      ...remainingMonthsGrouped,
    };
    if (select) {
      Object.values(data).forEach((transactions) => {
        transactions.forEach((transaction) => {
          const type = transaction.amount < 0 ? "Expense" : "Income";
          if (!allTransactions[type].includes(transaction.transactionUuid)) {
            allTransactions[type].push(transaction.transactionUuid);
          }
        });
      });
    } else if (unSelect) {
      setSelectedForBulkUpdate({
        Expense: [],
        Income: [],
      });
      return;
    }
    setSelectedForBulkUpdate(allTransactions);
  };

  const updateCategoriesInBulk = async (categoryId, type) => {
    setLoading(true);
    const data = {
      expenseTransactionUuids: [...selectedForBulkUpdate.Expense],
      incomeTransactionUuids: [...selectedForBulkUpdate.Income],
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category_In_Bulk}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactions();
      setSelectedForBulkUpdate({
        Expense: [],
        Income: [],
      });
      setSelectionMode(false);
      setSelectAll(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchTransactions = useCallback(async () => {
    setLoading(true);
    const data = {
      expensecategoryIds: [...selectedExpenseCategories],
      incomecategoryIds: [...selectedIncomeCategories],
      dateRageFilter: filterByDate,
    };
    try {
      const response = await axios.post(
        `${weburls.Get_Account_Transactions}${uuid}/${accountId}/700/1?name=${search}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      const groupedCurrentMonth = groupTransactionsByDate(
        response?.data?.data?.transactions?.thisMonth
      );
      const groupedRemainingMonths = groupTransactionsByMonth(
        response?.data?.data?.transactions?.remainingMonths
      );

      setCurrentMonthGrouped(groupedCurrentMonth.data);
      setRemainingMonthsGrouped(groupedRemainingMonths.data);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong, please try again.", {
        position: "top-right",
        autoClose: 2000,
      });
      setLoading(false);
    }
  }, [
    uuid,
    selectedExpenseCategories,
    selectedIncomeCategories,
    debouncedSearch,
    filterByDate,
  ]);

  useEffect(() => {
    getCategory();
    fetchTransactions();
    // fetchCashAccount();
  }, [getCategory, fetchTransactions, debouncedSearch]);

  useEffect(() => {
    if (debouncedSearch) {
      fetchTransactions();
    }
  }, [debouncedSearch, fetchTransactions]);

  const addTransaction = async (data) => {
    const payload = {
      userUuid: uuid,
      trans_name: data.name,
      trans_date: data.date,
      trans_amount: data.amount,
      accound_id: data.id,
      avatar: data.avatar,
      trans_type: data.transactionType.toUpperCase(),
    };
    try {
      const response = await axios.post(`${weburls.Add_Transaction}`, payload, {
        headers: {
          authorization: `Basic ${token}`,
        },
      });

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      fetchTransactions();
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const splitTransaction = async (splits, transactionId, type) => {
    try {
      const response = await axios.post(
        `${weburls.Split_Transaction}${uuid}/${transactionId}/${type}`,
        splits,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      fetchTransactions();
      closeModal("viewTransaction");
    } catch (error) {
      toast.error("Something went wrong while spliting transaction");
    }
  };

  const addNewCategory = (data, type) => {
    if (type === "Expense") {
      addNewExpenseCategory(data);
    } else {
      addNewIncomeCategory(data);
    }
  };

  const addNewExpenseCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Expense_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const addNewIncomeCategory = async (data) => {
    try {
      const response = await axios.post(
        `${weburls.Add_Income_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      if (response?.data?.code === 1) {
        toast.success(`${response?.data?.message}`);
      }
      if (response?.data?.code === 0) {
        toast.error(`${response?.data?.message}`);
      }
      getCategory();
    } catch (error) {
      toast.error("Somthing went wronge while adding expense category");
    }
  };

  const updateSingleCategory = async (categoryData) => {
    const data = {
      transactionUuid: categoryData.transactionId,
      categoryId: categoryData.userCategoryId,
      type: categoryData.type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      setSplitModalData((prev) => ({
        ...prev,
        masterCategoryColour: categoryData.colour,
        masterCategoryEmoji: categoryData.emoji,
        masterCategoryName: categoryData.name,
      }));
      fetchTransactions();
    } catch (error) {}
  };
  const updateTransactionCategory = async (transactionId, categoryId, type) => {
    const data = {
      transactionUuid: transactionId,
      categoryId: categoryId,
      type: type,
    };
    try {
      const response = await axios.post(
        `${weburls.Update_Category}${uuid}`,
        data,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );
      toast.success(`${response?.data?.message}`);
      fetchTransactions();
    } catch (error) {}
  };

  const handleTagClick = (id, type) => {
    if (type === "expense") {
      setSelectedExpenseCategories((prev) => {
        const newSelection = prev.includes(id)
          ? prev.filter((tag) => tag !== id)
          : [...prev, id];

        return newSelection.length > 0 ? newSelection : [0];
      });

      if (
        (selectedIncomeCategories.length === 0 ||
          selectedIncomeCategories[0] === 0) &&
        selectedExpenseCategories.length === 0
      ) {
        setSelectedIncomeCategories([0]);
      }
    } else if (type === "income") {
      setSelectedIncomeCategories((prev) => {
        const newSelection = prev.includes(id)
          ? prev.filter((tag) => tag !== id)
          : [...prev, id];

        return newSelection.length > 0 ? newSelection : [0];
      });

      // Only clear expense categories if no expense categories are selected AND it's the first income selection
      if (
        (selectedExpenseCategories.length === 0 ||
          selectedExpenseCategories[0] === 0) &&
        selectedIncomeCategories.length === 0
      ) {
        setSelectedExpenseCategories([0]); // Clear expense categories only on the first income selection
      }
    }
  };

  const handleDeleteCancel = () => {
    setShowDeletePopup(false);
  };
  const deleteTransactionRule = async () => {
    setLoading(true);
    try {
      const response = await axios.delete(
        `${weburls.Delete_cash_account}${uuid}/${accountId}`,
        {
          headers: {
            authorization: `Basic ${token}`,
          },
        }
      );

      if (response?.data.code === 1) {
        toast.success(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      if (response?.data.code === 0) {
        toast.error(response?.data?.message, {
          position: "top-right",
          autoClose: 2000,
        });
      }
      setLoading(false);
      navigate(`/AccountBalances/${uuid}`);
    } catch (error) {
      toast.error("Somthing went wrong...");
    }
  };

  const handleDeleteConfirm = async () => {
    await deleteTransactionRule();
    closeModal("updateTransactionRules");
  };

  return (
    <>
      <div className="app-main__outer your-merchants-main-wrapper">
        <div className="header-budget-main-div">
          <div className="header-budget-text-div">
            <PageTitle
              title={"Account details"}
              description={
                "Here’s all your account details including all your transactions as well."
              }
            />
          </div>

          <div className="header-budget-sub-div">
            {!isAkahu && (
              <div
                style={{
                  width: "327px",
                  height: "60px",
                  borderRadius: "360px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  zIndex: "1",
                  marginRight: "-240px",
                }}
              >
                <button
                  type="submit"
                  className="next-button-budget-detail cursor-pointer"
                  onClick={() => setShowDeletePopup(true)}
                >
                  Delete account
                </button>
              </div>
            )}
            <UserImageInfoToggle />
          </div>
        </div>
        <div className="d-flex gap-4 mt-5">
          <div className="account-detail-left">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <SectionTitle
                title={
                  isSelectionMode
                    ? `${
                        selectedForBulkUpdate.Expense.length +
                        selectedForBulkUpdate.Income.length
                      } Selected`
                    : "Account"
                }
              />
              <div className="d-flex gap-1">
                {!isSelectionMode ? (
                  <>
                    <span
                      onClick={() => setSelectionMode(true)}
                      className="cursor-pointer"
                      style={{ marginRight: "5px" }}
                    >
                      <BulkIcon />
                    </span>
                    <span
                      onClick={() => openModal("addTransactionRules")}
                      className="cursor-pointer"
                      style={{ marginRight: "5px" }}
                    >
                      <RulesIcon />
                    </span>
                  </>
                ) : (
                  <div className="selection-flex">
                    <div
                      className="d-flex align-items-center gap-1 cursor-pointer"
                      onClick={() => setSelectAll(!selectAll)}
                    >
                      <SelectAllIcon />
                      <SelectAll />
                      {/* <span className="select-all">Select all</span> */}
                    </div>
                    <span
                      className="select-done cursor-pointer"
                      onClick={handleDoneWithBulkUpdate}
                    >
                      Done
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="selected-account ">
              <div className="merchants-inner-wrp"></div>
              <div className="merchant-box ">
                <div>
                  <div className="d-flex align-items-center">
                    <div className="img-box">
                      <img
                        src={!isEmpty(avatar) ? avatar : Logo}
                        alt=""
                        style={{
                          borderRadius: "50%",
                        }}
                      />
                    </div>
                    <div className="ms-3">
                      <div className="text-merchant bold black">
                        {accountName}
                      </div>
                      <div className="small-text-merchant">
                        <h4 className="small-text-merchant space black">
                          <div className="setting_text_color-2">
                            {accountNumber}
                          </div>
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button-blck">
                  <div className="selected-amount">
                    <Amount amount={value} />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <SectionTitle title={"Transactions"} />
            </div>
            <div className="account-detail-left-wrapper">
              {!isEmpty(currentMonthGrouped) ||
              !isEmpty(remainingMonthsGrouped) ? (
                <TransactionListing
                  data={{ ...currentMonthGrouped, ...remainingMonthsGrouped }}
                  modalOpen={openModal}
                  expenseCategories={category}
                  updateTransactionCategory={updateTransactionCategory}
                  addNewCategory={addNewCategory}
                  incomeCategories={icomecat}
                  setSelectionMode={setSelectionMode}
                  isSelectionMode={isSelectionMode}
                  setSelectedForBulkUpdate={setSelectedForBulkUpdate}
                  selectedForBulkUpdate={selectedForBulkUpdate}
                  updateCategoriesInBulk={updateCategoriesInBulk}
                  selectAll={selectAll}
                  unPress={unPress}
                />
              ) : (
                <NoDataFound />
              )}
            </div>
          </div>
          <div className="account-detail-right sticky-sidebar">
            <div className="search-spacing filter-merchants-wrp-side">
              <div className="row">
                <div className="col-md-7 col-lg-12">
                  <SectionTitle title={"Search for transactions"} />
                  <div>
                    <div className="search-merchant your-merchant-search">
                      <form>
                        <input
                          type="text"
                          value={search}
                          className="search search-color-white"
                          onChange={(e) => setSearch(e.target.value)}
                        />
                        <Search className="search-merchant" />
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-between mt-3">
                <SectionTitle title={"Filter transactions"} />
              </div>
              <div className="merchants-background-color">
                <FilterTags
                  title={"Expense categories"}
                  categories={category}
                  selectedCategories={selectedExpenseCategories}
                  setSelectedCategories={setSelectedExpenseCategories}
                  handleTagClick={handleTagClick}
                  type={"expense"}
                />

                <FilterTags
                  title={"Income categories"}
                  categories={icomecat}
                  selectedCategories={selectedIncomeCategories}
                  setSelectedCategories={setSelectedIncomeCategories}
                  handleTagClick={handleTagClick}
                  type={"income"}
                />
                <div className="bold live-connection mt-3">Date range</div>
                <div className="account-date-group green-btn">
                  {dateOptions.map((option) => (
                    <div className="button-blck" key={option}>
                      <button
                        className={`account-date-btn ${
                          filterByDate === option ? "green" : ""
                        }`}
                        onClick={() =>
                          setFilterByDate(filterByDate === option ? "" : option)
                        }
                      >
                        {option}
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modals.viewTransaction && (
        <TransactionViewModal
          data={spliModalData}
          modalOpen={modals.viewTransaction}
          closeModal={closeModal}
          addTransaction={addTransaction}
          // accountData={accountData}
          splitTransaction={splitTransaction}
          expenseCategories={category}
          incomeCategories={icomecat}
          updateSingleCategory={updateSingleCategory}
          deleteCustomTransaction={deleteCustomTransaction}
        />
      )}
      {modals.addTransactionRules && (
        <AddTransactionRuleModel
          modalOpen={modals.addTransactionRules}
          closeModal={closeModal}
          expenseCategories={category}
          incomeCategories={icomecat}
        />
      )}
      {showDeletePopup && (
        <PopUpDelete
          handleDeleteCancel={handleDeleteCancel}
          handleDeleteConfirm={handleDeleteConfirm}
        />
      )}
      {loading && (
        <OverlayContainer>
          <div>
            <Loading />
          </div>
        </OverlayContainer>
      )}
    </>
  );
};

export default AccountDetails;
