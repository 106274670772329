import React from "react";
import PopupModal from "../../ModalForm/Popup/PopupModel";

import "./Moneyinsights.css";
import { getDisplayOption } from "../../utilities/Statics";

const CategoryBreakdownSection = ({
  togglePopupTopExpenses,
  isPopupOpenTopExpenses,
  selectedTopExpensesOption,
  handleOptionTopExpensesClick,
  heading,
  period,
}) => {

  return (
    <div className="row ">
      <div
        className="expense-summary-section"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "10px",
          marginTop: "2px",
        }}
      >
        <div>
          <h2
            style={{
              margin: 0,
              fontSize: "20px",
              fontweight: "500",
              marginBottom: "4px",
            }}
          >
            {heading}
          </h2>
        </div>
        <div className="col-md-6 right-section">
          <div className="">
            <button
              className="group-button green"
              onClick={togglePopupTopExpenses}
              aria-haspopup="true"
              aria-expanded={isPopupOpenTopExpenses}
              id="Open_Popup_Modal"
            >
              {getDisplayOption(selectedTopExpensesOption)}
            </button>
            {isPopupOpenTopExpenses && (
              <PopupModal
                targetId="Open_Popup_Modal"
                isOpen={isPopupOpenTopExpenses}
                onClose={togglePopupTopExpenses}
                title="Date Range"
                options={
                  period === "thisCustom" || period === "lastCustom"
                    ? ["Custom", "Custom - last period"]
                    : [
                        "Last week",
                        "This week",
                        "Last fortnight",
                        "This fortnight",
                        "Last month",
                        "This month",
                        "Last quarter",
                        "This quarter",
                        "This year",
                      ]
                }
                selectedOption={getDisplayOption(selectedTopExpensesOption)}
                handleOptionClick={handleOptionTopExpensesClick}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryBreakdownSection;
