import moment from "moment";
import React, { useState } from "react";
import ListingCategories from "../Transactions/ListingCategories";
import AddCategoriesModal from "../../ModalForm/TransactionModals/AddCategoriesModal";
import { categoryBackgroundCss, categoryColorForAll } from "../../utilities/Statics";
import { useNavigate, useParams } from "react-router-dom";

// Helper function to format amount in USD
const formatAmount = (amount) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);

export const MerchantListing = ({
  merchant,
  onClick,
  merchantCategories,
  updateMerchantCategory,
  data,
  addNewCategory,
}) => {
  const [showAddCategory, setShowAddCategory] = useState(false);
  const navigate = useNavigate();
  const { uuid } = useParams();
  const [popoverOpen, setPopoverOpen] = useState({});
  const [transactionType, setTransactionType] = useState();

  const togglePopover = (transactionId, amount) => {
    setPopoverOpen((prev) => ({
      ...prev,
      [transactionId]: !prev[transactionId],
    }));
    setTransactionType(amount < 0 ? "Expense" : "Income");
  };
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  const handleAddCategory = () => {
    setShowAddCategory(!showAddCategory);
    setPopoverOpen({});
  };
  const handleClick = () => {
    navigate(`/merchantdetails/${uuid}`, {
      state: {
        merchantName: merchant.name,
        merchantLogo: merchant.logo,
        merchantUuid: merchant.merchantId,
        merchantCategoryName: merchant.categoryName,
        merchantCategoryEmoji: merchant.categoryEmoji,
        merchantCategoryColour: merchant.categoryColor,
        merchantAmount: merchant.amount,
        merchantCount: merchant.transactioncount
      },
    });
  };
  return (
    <div className="merchant-box mt-3" key={merchant.merchantsUuid}>
      <div
        className="d-flex w-75"
        onClick={handleClick}
        style={{ cursor: "pointer" }}
      >
        <div className="img-box" style={{ marginRight: "5px" }}>
          <img src={merchant.logo} alt="" style={{ borderRadius: "50%" }} />
        </div>
        <div className="ms-3">
          <div
            className="text-merchant bold black"
            style={{ fontWeight: "bold", color: "black", marginBottom: "0px" }}
          >
            {merchant?.name}
          </div>
          {/* Updated Amount and Transaction Count Section */}
          <div
            className="small-text-merchant"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              fontSize: "14px",
            }}
          >
            <span
              className={
                merchant.amount < 0 ? "number-merchant" : "expense-amount"
              }
              style={{
                color: merchant.amount < 0 ? "#D04F4F" : "#40AF66",
                marginRight: "0px",
              }}
            >
              {formatAmount(merchant.amount)}
            </span>
            <span className="black">|</span>
            <span
              className="setting_text_color-2"
              style={{ color: "#9B9B9B", fontWeight: "500", fontSize: "14px" }}
            >
              {merchant.transactioncount}{" "}
              {Number(merchant.transactioncount) === 1
                ? "Transaction"
                : "Transactions"}
            </span>
          </div>
        </div>
      </div>

      <div className="button-blck">
        <div
          className="icon-tag"
          id={`Popover-${merchant.merchantId}`}
          onClick={() => togglePopover(merchant.merchantId, merchant?.amount)}
          style={{ marginBottom: "0px" }}
        >
          {merchant.categoryName !== "Uncategorised" && (
            <div
              className={`icon-transaction-img ${
                isHexColor(merchant.categoryColor)
                  ? ""
                  : categoryBackgroundCss(merchant.categoryColor)
              }`}
              style={{
                background: categoryColorForAll(merchant.categoryColor),
              }}
            >
              {merchant.categoryEmoji}
            </div>
          )}
          <div className="content">
            <h6 className="icon-content">{merchant.categoryName}</h6>
          </div>
        </div>

        <ListingCategories
          categories={merchantCategories}
          updateTransactionCategory={updateMerchantCategory}
          transactionId={merchant.merchantId}
          togglePopover={togglePopover}
          popoverOpen={popoverOpen}
          showAddCategory={showAddCategory}
          handleChange={handleAddCategory}
          transactionType={"Merchant"}
        />
      </div>

      {showAddCategory && (
        <AddCategoriesModal
          modalOpen={showAddCategory}
          addNewCategory={addNewCategory}
          transactionType={"Expense"}
          closeModal={handleAddCategory}
        />
      )}
    </div>
  );
};
