import React, { forwardRef } from "react";
import "./index.css";

const Cinput = forwardRef(
  ({ onChange, name, type = "text", value, width }, ref) => {


    const handleChange = (e) => {
      let inputValue = e.target.value;

      // to Show dollar sign for type Number
      if (type === "number") {
        inputValue = inputValue.replace(/[^0-9$]/g, "");
        if (inputValue.startsWith("$") && inputValue.length > 1) {
          inputValue = "$" + inputValue.replace(/^\$/, "");
        }
        onChange({ target: { name, value: inputValue.replace(/^\$/, "") } });
      } else {
        onChange(e); 
      }
    };

    return (
      <input
        style={{ width }}
        type={type === "number" ? "text" : type}
        name={name}
        value={type === "number" && value ? `$${value}` : value}
        ref={ref}
        className="custom-input"
        onChange={handleChange}
      />
    );
  }
);

export default Cinput;
