import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowAccoor } from "../../../assests/images/icons/accordionArrow.svg";
import "./BudgetSummary.css";
import IncomeExpenseBudgetModal from "./IncomeExpenseBudget/IncomeExpenseBudgetModal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as OverBudget } from "../../../assests/images/over_budget.svg";
import { progressbarColor } from "../../../utilities/Statics";
import { lightBackgroundColor } from "../../../utilities/Statics";
import { motion } from "framer-motion";
import { ReactComponent as DoneWithBackground } from "../../../assests/images/doneWithBackground.svg";

const BudgetsComponent = ({
  incomeExpenseBudgetsData = [],
  mainHeading,
  isIncome,
  setTotalAmount,
  setRemainingAmount,
  selectedPeriod,
  showFields,
  uuid,
  fetchBudgetData,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const handleCardClick = (budgetId, incomeExpenseBudgetsData) => {
    const categoryType = incomeExpenseBudgetsData?.category_type?.toLowerCase();
    if (categoryType) {
      navigate(
        `/budget/${uuid}/budgetdetails/${categoryType}/${budgetId}?period=${selectedPeriod}`
      );
    } else {
      console.error("Error: category_type is undefined");
    }
  };

  let totalMonthly = 0.0;
  let totalRemainingMonthly = 0.0;
  let totalAdjustedMonthly = 0.0;

  totalMonthly = incomeExpenseBudgetsData?.length
    ? incomeExpenseBudgetsData.reduce(
        (total, budget) =>
          total +
          (isIncome
            ? budget.total_transaction_amount
            : budget.total_transaction_amount),
        0
      )
    : 0;

  totalAdjustedMonthly = incomeExpenseBudgetsData?.length
    ? incomeExpenseBudgetsData.reduce(
        (total, budget) =>
          total +
          (isIncome
            ? budget.adjusted_income_amount
            : budget.adjusted_expense_amount),
        0
      )
    : 0;

  totalRemainingMonthly = incomeExpenseBudgetsData?.reduce((total, budget) => {
    const adjustedAmount = isIncome
      ? budget.adjusted_income_amount - budget.total_transaction_amount
      : budget.adjusted_expense_amount - budget.total_transaction_amount;
    return total + adjustedAmount;
  }, 0);

  const roundedAdjustedAmount = totalRemainingMonthly.toFixed(2);
  let progress;

  if (isIncome) {
    if (totalAdjustedMonthly === 0) {
      progress = 0;
    } else {
      progress = (totalMonthly / totalAdjustedMonthly) * 100;
    }
    progress = progress > 100 ? 100 : progress;
  } else {
    if (totalAdjustedMonthly === 0) {
      progress = 0;
    } else {
      progress = (totalMonthly / totalAdjustedMonthly) * 100;
    }
    progress = progress > 100 ? 100 : progress;
  }
  useEffect(() => {
    if (typeof setTotalAmount === "function") {
      setTotalAmount((prevState) => ({
        ...prevState,
        [isIncome ? "income" : "expense"]: totalMonthly,
      }));
    }
  }, [totalMonthly, isIncome, setTotalAmount]);

  useEffect(() => {
    if (typeof setRemainingAmount === "function") {
      if (isIncome) {
        const totalIncome = incomeExpenseBudgetsData.reduce(
          (total, budget) => total + (budget.adjusted_income_amount || 0),
          0
        );
        setRemainingAmount((prevState) => ({
          ...prevState,
          income: totalIncome,
        }));
      } else {
        const totalExpense = incomeExpenseBudgetsData.reduce(
          (total, budget) => total + (budget.adjusted_expense_amount || 0),
          0
        );
        setRemainingAmount((prevState) => ({
          ...prevState,
          expense: totalExpense,
        }));
      }
    }
  }, [incomeExpenseBudgetsData, isIncome, setRemainingAmount]);

  return (
    <div className="col-md-12">
      <div className="budget-breakdown-header">
        <h4>{mainHeading} budgets</h4>
        <div className="col-md-6 right-section">
          <i
            className="fa-solid fa-plus"
            style={{
              width: "20px",
              fontSize: "20px",
              cursor: "pointer",
            }}
            onClick={toggleModal}
          ></i>
        </div>
      </div>
      <IncomeExpenseBudgetModal
        isModalOpen={isModalOpen}
        isOpen={isModalOpen}
        toggleModal={toggleModal}
        mainHead={mainHeading}
        showFields={showFields}
        isIncome={isIncome}
        fetchBudgetData={fetchBudgetData}
        selectedPeriod={selectedPeriod}
      />
      {incomeExpenseBudgetsData && (
        <div className="budget-item-card">
          {incomeExpenseBudgetsData?.map((budget, index) => {
            let progress;

            const adjustedAmount = isIncome
              ? budget.adjusted_income_amount - budget.total_transaction_amount
              : budget.adjusted_expense_amount -
                budget.total_transaction_amount;

            const totalAmount = isIncome
              ? budget.total_transaction_amount
              : budget.total_transaction_amount;

            if (isIncome) {
              if ((budget.adjusted_income_amount ?? 0) === 0) {
                progress =
                  (budget.total_transaction_amount ?? 0) === 0.0 ? 0.0 : 100;
              } else {
                progress =
                  ((budget.total_transaction_amount ?? 0) /
                    (budget.adjusted_income_amount ?? 0)) *
                  100;
              }
            } else {
              progress =
                (budget.adjusted_expense_amount ?? 0) === 0
                  ? (budget.total_transaction_amount ?? 0) === 0.0
                    ? 0.0
                    : 100
                  : ((budget.total_transaction_amount ?? 0) /
                      (budget.adjusted_expense_amount ?? 0)) *
                    100;
            }
            progress = progress > 100 ? 100 : progress;
            const categoryColor = budget.colour;
            const progressBarColor = progressbarColor(categoryColor);
            const backgroundColor = lightBackgroundColor(categoryColor);

            return (
              <div
                key={index}
                className="budget-item"
                onClick={() =>
                  handleCardClick(
                    isIncome ? budget.income_uuid : budget.expense_uuid,
                    budget,
                    adjustedAmount,
                    totalAmount,
                    progress
                  )
                }
                style={{ cursor: "pointer" }}
              >
                <div className="budget-label">
                  <div className="pay-budget-sec">
                    <div
                      className="budget-icon"
                      style={{
                        backgroundColor: backgroundColor,
                      }}
                    >
                      {budget.emoji}
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "7px",
                        marginLeft: "5px",
                      }}
                    >
                      <h6>{budget.category_name}</h6>
                      <h4 style={{ marginTop: "-5px" }}>
                        ${totalAmount.toLocaleString()}
                      </h4>
                    </div>
                  </div>
                  <p className="header-p" style={{ cursor: "pointer" }}>
                    <div className="account-number inter text-16px">
                      <div className="button-blck">
                        <ArrowAccoor />
                      </div>
                    </div>
                  </p>
                </div>
                <div className="budget-remaining">
                  {adjustedAmount < 0 ? (
                    isIncome ? (
                      <>
                        {`You've earnt $${Math.abs(
                          adjustedAmount
                        ).toLocaleString()} more`}
                        <DoneWithBackground
                          style={{
                            marginTop: "4px",
                            marginLeft: "3px",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        {`$${Math.abs(
                          adjustedAmount
                        ).toLocaleString()} over budget`}
                        <OverBudget
                          style={{
                            marginLeft: "1px",
                            marginTop: "2px",
                          }}
                        />
                      </>
                    )
                  ) : (
                    `$${adjustedAmount.toLocaleString()} remaining`
                  )}
                </div>
                <div
                  className="budget-progress-container"
                  style={{ backgroundColor: backgroundColor }}
                >
                  <motion.div
                    className="budget-progress"
                    style={{
                      width: `${progress}%`,
                      backgroundColor: progressBarColor,
                    }}
                    initial={{ width: 0 }}
                    animate={{ width: `${progress}%` }}
                    
                    aria-valuenow={progress.toFixed(2)}
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></motion.div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <div className="budget-item-card" style={{ marginTop: "25px" }}>
        <div className="budget-item">
          <div className="budget-label">
            <div className="pay-budget-sec">
              <div>
                <h6>Total {isIncome ? "Income" : "Expenses"}</h6>
                <h4>${Math.abs(totalMonthly).toLocaleString()}</h4>
              </div>
            </div>
          </div>
          <div className="budget-remaining">
            {!isIncome ? (
              totalRemainingMonthly < 0 ? (
                <>
                  {`$${Math.abs(
                    totalRemainingMonthly
                  ).toLocaleString()} over budget`}
                  <OverBudget
                    style={{
                      marginLeft: "1px",
                      marginTop: "2px",
                    }}
                  />
                </>
              ) : (
                `$${Math.abs(totalRemainingMonthly).toLocaleString()} remaining`
              )
            ) : roundedAdjustedAmount < 0 ? (
              <>
                {`You've earnt $${Math.abs(
                  roundedAdjustedAmount
                ).toLocaleString()} more`}
                <DoneWithBackground
                  style={{
                    marginLeft: "3px",
                    marginTop: "4px",
                  }}
                />
              </>
            ) : (
              `$${Math.abs(roundedAdjustedAmount).toLocaleString()} remaining`
            )}
          </div>
          <div
            className={`budget-progress-container`}
            style={{
              backgroundColor: lightBackgroundColor(isIncome ? "Green" : "Red"),
            }}
          >
            <motion.div
              className={`budget-progress `}
              style={{
                width: `${progress}%`,
                backgroundColor: progressbarColor(isIncome ? "Green" : "Red"),
              }}
              initial={{ width: 0 }}
              animate={{ width: `${progress}%` }}
              transition={{ duration: 0.5 }}
              aria-valuemin="0"
              aria-valuemax="100"
            ></motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BudgetsComponent;
