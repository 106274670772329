import React from "react";
import "./BudgetSummary.css";
import { formatNumberWithCommas } from "../../../utilities/helper.jsx";
import { motion } from "framer-motion";

const BudgetBreakdown = ({
  budgetData,
  handleToggleClick,
  isBudgetBreakToggled,
  totalAmount,
  remainingAmount,
}) => {
  const displayedAmount = isBudgetBreakToggled ? remainingAmount : totalAmount;

  const incomeLabel = isBudgetBreakToggled
    ? "Total budgeted income"
    : "Total actual income";
  const expenseLabel = isBudgetBreakToggled
    ? "Total budgeted expenses"
    : "Total actual expenses";

  const budgetSummaryData = [
    {
      label: incomeLabel,
      amount: displayedAmount?.income
        ? Number(displayedAmount?.income).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "0.00",
      progress: Math.min(
        (displayedAmount?.income / totalAmount.income) * 100 || 0,
        100
      ),
      progressColor: "#469B88",
      backgroundColor:"#DAEBE7"
    },
    {
      label: expenseLabel,
      amount: displayedAmount?.expense
        ? Number(displayedAmount?.expense).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : "0.00",
      progress:
        displayedAmount?.expense < 0
          ? 100
          : Math.min(
              (displayedAmount.expense / totalAmount.expense) * 100 || 0,
              100
            ),
      progressColor: "#FE3030",
      backgroundColor:"#FFD6D6"

    },
  ];

  const leftOver =
    displayedAmount.income && displayedAmount.expense
      ? (displayedAmount.income - displayedAmount.expense).toFixed(2)
      : 0;

  const isNegative = leftOver < 0;
  const absoluteAmount = Math.abs(leftOver);
  const formattedAmount = formatNumberWithCommas(absoluteAmount);
  const displayAmount = isNegative
    ? `-$${formattedAmount}`
    : `$${formattedAmount}`;

  return (
    <div className="col-md-4">
      <div className="budget-breakdown-header">
        <h4>Budget breakdown</h4>
        <button
          className="toggle-button"
          type="button"
          style={{
            borderRadius: "30px",
            background: "#479985",
            color: "white",
            padding: "5px 13px 5px 13px",
            marginBottom: "8px",
          }}
          onClick={handleToggleClick}
        >
          {isBudgetBreakToggled ? "Budgeted" : "Actual"}
        </button>
      </div>

      {/* Budget Breakdown Card */}
      <div className="budget-breakdown-card">
        <div className="summary" style={{ marginTop: "0px" }}>
          <h5>Summary</h5>

          {/* Mapping through budget summary data */}
          {budgetSummaryData.map((item, index) => (
            <div key={index} className="summary-item">
              <div className="label">
                <span>{item.label}</span>
                <span className="amount">${item.amount.toLocaleString()}</span>
              </div>

              <div
                className={`budget-progress-container`}
                style={{
                  backgroundColor: item.backgroundColor,
                }}
              >
                <motion.div
                  className={`budget-progress `}
                  style={{
                    width: `${100}%`,
                    backgroundColor: item.progressColor,
                  }}
                  initial={{ width: 0 }}
                  animate={{ width: `${100}%` }}
                  transition={{ duration: 0.5 }}
                ></motion.div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Left Over Section */}
      <div
        className="left-over-card"
        style={{
          backgroundColor: isNegative ? "#D04F4F" : "#469B88",
          color: "white",
        }}
      >
        <h3>{displayAmount}</h3>
        <p>Left over</p>
      </div>
    </div>
  );
};

export default BudgetBreakdown;
