export const PopUpForUpdateDelete = ({
  handleUpdateCashflowYes,
  handleUpdateCashflowNo,
  updateOrDeleteText,
}) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Update"
    >
      <div className="popup-content">
        <h2>{updateOrDeleteText} cashflow?</h2>
        <p>Would you like to {updateOrDeleteText} your cashflow as well?</p>
        <div className="popup-buttons">
          <button className="btn btn-no" onClick={handleUpdateCashflowNo}>
            No
          </button>
          <button className="btn btn-yes" onClick={handleUpdateCashflowYes}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpForDelete = ({ handleDeleteConfirm, handleDeleteCancel }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="popup-content">
        <h2>Are you sure?</h2>
        <p>This cannot be un-done.</p>
        <div className="popup-buttons">
          <button className="btn btn-no" onClick={handleDeleteCancel}>
            Go back
          </button>
          <button className="btn btn-yes" onClick={handleDeleteConfirm}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpDelete = ({ handleDeleteConfirm, handleDeleteCancel }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Are you sure?</h2>
        <p className="delete-popup-sub-text">This cannot be un-done.</p>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={handleDeleteCancel}>
            Go back
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={handleDeleteConfirm}>
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpNoYes = ({ title, subtitle, handleChange }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">{title}</h2>
        <p className="delete-popup-sub-text">{subtitle}</p>
        <div className="delete-popup-buttons">
          <button className="btn-delete" onClick={() => handleChange(false)}>
            No
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-confirm" onClick={() => handleChange(true)}>
            Yes
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpYesNoRow = ({ title, subtitle, handleChange }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">{title}</h2>
        <p className="delete-popup-sub-text">{subtitle}</p>
        <div className="popup-buttons">
          <button className="btn-confirm" onClick={() => handleChange(true)}>
            Yes
          </button>
          <button className="btn-delete" onClick={() => handleChange(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export const PopUpRuleUpdate = ({ handleChanges }) => {
  return (
    <div
      className="popup-container modal-background open-modal"
      id="popup_Delete"
    >
      <div className="delete-popup-content">
        <h2 className="delete-popup-text">Apply to current transactions?</h2>
        <p className="delete-popup-sub-text">
          Would you like to apply this rule to all current transactions?
        </p>
        <div className="delete-popup-buttons">
          <button className="btn-confirm" onClick={() => handleChanges(true)}>
            Yes
          </button>
        </div>
        <div className="delete-popup-buttons mt-2">
          <button className="btn-delete" onClick={() => handleChanges(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};
