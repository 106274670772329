import { useCallback, useEffect, useState } from 'react'
import { parseYourAccountBalancesData } from '../utilities/helper';
import weburls from '../Weburls/weburls';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

const useAccount = () => {
    const [accountData,setAccountData]=useState([])
    const [accountLoading, setLoading] = useState(false);
    const { uuid } = useParams();

    useEffect(()=> {
        fetchCashAccount()
    },[])

    const fetchCashAccount = useCallback(async () => {
        setLoading(true);
        try {
          const response = await axios.get(`${weburls.Get_Cash_Account}${uuid}`);
          if (response?.data) {
            const parsedData = parseYourAccountBalancesData(
              response?.data?.accounts
            );
            setAccountData(parsedData);
          }
          setLoading(false);
        } catch (error) {
          toast.error("Something went wrong please try again.", {
            position: "top-right",
            autoClose: 2000,
          });
        }
      }, [uuid]);
      
      return {
        accountData,
        accountLoading,
    };
      
}

export default useAccount