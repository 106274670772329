import React, { useEffect, useRef, useState } from "react";
import { Popover, PopoverBody } from "reactstrap";
import { categoryBackgroundCss, categoryColorForAll } from "../../utilities/Statics";

const ListingCategories = ({
  categories,
  position,
  transactionId,
  updateTransactionCategory,
  togglePopover,
  popoverOpen,
  handleChange,
  transactionType,
  updateCategoriesInBulk,
  isSelectionMode,
  isPendingTransaction,
  isNewCategoryPopup = true,
}) => {
  const popoverRef = useRef(null);
  const handleCategoryClick = (category) => {
    if (isSelectionMode) {
      updateCategoriesInBulk(category.userCategoryId, category.type);
    } else {
      updateTransactionCategory(
        transactionId,
        category.userCategoryId,
        category.type
      );
    }
    togglePopover(transactionId);
  };
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  // Close the popover if clicked outside
  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      popoverOpen[transactionId]
    ) {
      togglePopover(transactionId);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [popoverOpen, transactionId]);

  return (
    <>
      {!isPendingTransaction && (
        <Popover
          className="custom-popover"
          placement="bottom"
          isOpen={popoverOpen[transactionId] || false}
          target={`Popover-${transactionId}`}
          toggle={() => togglePopover(transactionId)}
          innerRef={popoverRef}
        >
          <PopoverBody>
            <div
              className="transaction-categories  mt-3"
              style={{ top: position?.top, left: position?.left }}
            >
              <div className="bold popover-live">
                {transactionType} categories
              </div>
              <button
                onClick={() => togglePopover(transactionId)}
                className="close-pass-button"
              >
                <i class="fa fa-times"></i>
              </button>
              <div className="categories group-btn green-btn">
                <div className="categories group-btn green-btn ">
                  {categories &&
                    categories?.length > 0 &&
                    categories.map((it) => (
                      <div
                        key={it?.userCategoryId}
                        className="button-blck"
                        onClick={() => handleCategoryClick(it)}
                        style={{ marginRight: "10px" }}
                      >
                        <div className={`icon-tag`}>
                          {it?.name !== "Uncategorised" && (
                            <div
                              className={`icon-transaction-img ${
                                isHexColor(it?.colour)
                                  ? ""
                                  : categoryBackgroundCss(it?.colour)
                              }`}
                              style={
                               { backgroundColor: categoryColorForAll(it?.colour) }
                              }
                            >
                              {it?.emoji}
                            </div>
                          )}
                          <div className="content">
                            <h6 className={`icon-content hello`}>{it?.name}</h6>
                          </div>
                        </div>
                      </div>
                    ))}
                  {isNewCategoryPopup && (
                    <button
                      className={`new-category-btn green ${
                        true <= 0 ? "green" : ""
                      }`}
                      onClick={handleChange}
                    >
                      + New category
                    </button>
                  )}
                </div>
              </div>
            </div>
          </PopoverBody>
        </Popover>
      )}
    </>
  );
};

export default ListingCategories;
