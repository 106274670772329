import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import appleIcon from '../../assests/images/App-Store-Black.png';
import { ReactComponent as EyeClose } from '../../assests/images/eye-close-icon.svg';
import { ReactComponent as EyeOpen } from '../../assests/images/eye-open-icon.svg';
import googlePlayIcon from '../../assests/images/Google-Play-Black.png';
import { ReactComponent as WordLogoBlack } from '../../assests/images/WordLogoBlack.svg';
import webUrls from '../../Weburls/weburls';
import './Login.css';
import { ClipLoader } from 'react-spinners';
const schema = yup.object().shape({
  email: yup
    .string()
    .required('Email is required')
    .matches(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/, 'Please enter a valid email'),
  password: yup.string().required('*Please enter your password'),
});
const Login = () => {
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [passwordType, setPasswordType] = useState('password');
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });
  const togglePassword = () => {
    setPasswordType((prevType) => (prevType === 'password' ? 'text' : 'password'));
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData({
      ...formData,
      [name]: trimmedValue,
    });
  };
  const onSubmitHandler = async (formData) => {
    setLoading(true);
    try {
      const response = await axios.post(webUrls.Advisor_Login, formData);
      localStorage.setItem('budget-token', response.data.token);
      localStorage.setItem(
        'userData',
        JSON.stringify({
          userId: response.data.userId,
          userUuid: response.data.userUuid,
          firstName: response.data.firstName,
          lastName: response.data.lastName,
          username: response.data.username,
          pinEnabled: response.data.pinEnabled,
        })
      );
      localStorage.setItem('roleId', JSON.stringify(response.data.roleId || 5));
      localStorage.setItem('userUuid', response.data.userUuid);
      if (response?.data?.code === '0') {
        return toast.error(`${response?.data?.message}`);
      }
      const userRole = JSON.parse(localStorage.getItem('roleId'));
      // setTimeout(() => {
      //   if (userRole === 1) {
      //     navigate('/SuperAdmin');
      //   } else if (userRole === 5) {
      //     navigate(`/Dashbaordpanel/${response.data.userUuid}`);
      //   } else {
      //     navigate('/Dashboard');
      //   }
      // }, 1000);
      setTimeout(() => {
        if (userRole === 1) {
          navigate('/SuperAdmin');
        } else if (userRole === 5) {
          navigate(`/budget/${response.data.userUuid}`);
        } else {
          navigate('/budget');
        }
      }, 1000);
    } catch (error) {
      toast.error(error.response?.data?.error || 'Login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  const logoRef = useRef(null);
  const formRef = useRef(null);
  const appleIconRef = useRef(null);
  const googlePlayIconRef = useRef(null);
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
    const observerCallback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-up');
          observer.unobserve(entry.target);
        }
      });
    };
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    if (logoRef.current) observer.observe(logoRef.current);
    if (formRef.current) observer.observe(formRef.current);
    if (appleIconRef.current) observer.observe(appleIconRef.current);
    if (googlePlayIconRef.current) observer.observe(googlePlayIconRef.current);
    return () => {
      if (logoRef.current) observer.unobserve(logoRef.current);
      if (formRef.current) observer.unobserve(formRef.current);
      if (appleIconRef.current) observer.unobserve(appleIconRef.current);
      if (googlePlayIconRef.current) observer.unobserve(googlePlayIconRef.current);
    };
  }, []);
  return (
    <>
      <style>{`
        .slide-up {
          animation: slide-up 0.5s ease-out forwards;
        }
        @keyframes slide-up {
          0% {
            transform: translateY(80px);
            opacity: 0;
          }
          100% {
            transform: translateY(0);
            opacity: 1;
          }
        }
      `}</style>
      <ToastContainer position="top-right" autoClose={5000} closeOnClick />
      <section className="login-section loginSectionWelcome">
        <div className="container-fluid h-custom">
          <div className="row d-flex justify-content-center align-items-center h-100">
            <div
              className="col-md-8 col-lg-6 col-xl-4"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <div className="logoCss text-center mb-4" ref={logoRef}>
                <WordLogoBlack />
              </div>
              <div className="loginFormWrp" ref={formRef}>
                <p className="text-center mb-5 mt-4 LoginText">Login</p>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <div className="input-header-text">Email</div>
                  <div className="form-outline">
                  <div
                      className="welcome-form-input-box"
                      style={{ display: 'flex', alignItems: 'center', height: '100%', marginBottom: '20px' }}
                    >
                      <div
                        className="input-text-box-margin"
                        style={{ paddingTop: '13px', paddingBottom: '13px', width: '100%' }}
                      >
                        <input
                          {...register('email', {
                            onChange: handleChange,
                          })}
                          value={formData.email}
                          name="email"
                          type="text"
                          id="form3Example3"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontFamily: 'Neurialgrotesk',
                          }}
                        />
                      </div>
                      <p className="mt-1 error-message mt-3">{errors.email?.message}</p>
                    </div>
                  </div>
                  <div className="input-header-text">Password</div>
                  <div className="form-outline">
                    <div className="welcome-form-input-box"
                      style={{ display: 'flex', alignItems: 'center', height: '100%' }}
                    >
                      <div style={{ marginLeft: '12px', flex: '1' }}>
                        <input
                          {...register('password', {
                            onChange: handleChange,
                          })}
                          type={passwordType}
                          name="password"
                          id="form3Example4"
                          className="register-focus"
                          style={{
                            fontWeight: 400,
                            fontSize: '14px',
                            border: 'none',
                            outline: 'none',
                            width: '100%',
                            fontFamily: 'Neurialgrotesk',
                          }}
                        />
                      </div>
                      <div className="" style={{ marginRight: '20px', paddingTop: '13px', paddingBottom: '13px' }} onClick={togglePassword}>
                        {passwordType === 'password' ? <EyeClose /> : <EyeOpen />}
                      </div>
                    </div>
                    <p className="mt-1 error-message">{errors.password?.message}</p>
                  </div>
                  <div className="d-flex justify-content-end align-items-center"
                  style={{ paddingTop: '13px' }}>
                    <a href="/ForgotPassword" className="text-green forgot-text">
                      Forgot password?
                    </a>
                  </div>
                  <div className="text-center mt-4 pt-2">
                  <button
                      type="submit"
                      className={`comn-btn ${loading ? 'sign-btn-load' : 'sign-btn'}`}
                      style={{ marginBottom: '20px' }}
                      disabled={loading} // Disables the button while loading
                    >
                      {loading ? <ClipLoader size={15} color={'#FFFFFF'} /> : 'Sign in'}
                    </button>
                    <div className="d-flex flex-row align-items-center justify-content-center mt-4 mb-4">
                      <span className="textContainer">
                        Want to manage your account?{' '}
                        <a href="/manageAccountLogin" className="text-green" style={{ fontWeight: 700 }}>
                          Manage
                        </a>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
              <div className="store-image login-icon-width d-flex flex-row align-items-center justify-content-center mt-3 gap-3">
                <a href="https://apps.apple.com/nz/app/budgetbuddie/id6452397222" target="_blank" className="" ref={appleIconRef}>
                  <img src={appleIcon} style={{ width: '150px' }} />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.budgetbuddie.app"
                  target="_blank"
                  className=""
                  style={{ textDecoration: 'none' }}
                  ref={googlePlayIconRef}
                >
                  <img src={googlePlayIcon} style={{ width: '150px' }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Login;