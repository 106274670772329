import React from 'react';
import { categoryBackgroundCss, categoryColorForAll } from '../../utilities/Statics';

const FilterTags = ({
  title,
  categories = [],
  selectedCategories,
  setSelectedCategories,
  handleTagClick,
  type,
}) => {
  const isHexColor = (color) => /^#([0-9A-Fa-f]{3}){1,2}$/.test(color);

  return (
    <div>
      <div className="bold live-connection">{title}</div>
      <div className="group-btn green-btn">
        <div className="button-blck" style={{ marginRight: "10px" }}>
          {/* Toggle "All" button. It should toggle between selected/deselected */}
          <button
            className={`all-btn ${selectedCategories.length === 0 ? "green" : ""}`}
            onClick={() => setSelectedCategories(selectedCategories.length === 0 ? [0] : [])}  // Toggle "All"
          >
            All
          </button>
        </div>

        {categories.length > 0 &&
          categories.map((category) => (
            <div key={category.userCategoryId} className="button-blck" style={{ marginRight: "10px" }}>
              <div
                className={`icon-tag ${selectedCategories.includes(category.userCategoryId) ? "green" : ""}`}
                onClick={() => handleTagClick(category.userCategoryId, type)}
              >
                 {category.name !== "Uncategorised" && (
                    <div
                    className={`icon-transaction-img ${isHexColor(category.colour) ? '' : categoryBackgroundCss(category.colour)}`}
                    style={{
                      background: categoryColorForAll(category.colour),
                    }}
                    >
                      {category.emoji}
                    </div>
                   )} 
                <div className="content">
                  <h6
                    className={`icon-content ${selectedCategories.includes(category.userCategoryId) ? "icon-white" : ""}`}
                  >
                    {category.name}
                  </h6>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default FilterTags;
